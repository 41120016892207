export const graphColors = [
  {
    mid: '#9ad6f9',
    darker: '#7ea8c0',
    lighter: 'rgba(201,240,249,0.4)' //'#c9f0f9'
  },
  {
    mid: '#d3b1ff',
    darker: '#a690c3',
    lighter: 'rgba(244, 220, 252,0.4)' // '#f4dcfc'
  },
  {
    mid: '#ff9154',
    darker: '#bc754f',
    lighter: 'rgba(249, 214, 168,0.4)' //'#f9d6a8'
  },
  {
    mid: '#b3e066',
    darker: '#8a9c65',
    lighter: 'rgba(227, 242, 184,0.4)' // '#e3f2b8'
  },
  {
    mid: '#8f98fa',
    darker: '#7b7baa',
    lighter: 'rgba(221, 209, 251,0.4)' // '#ddd1fb'
  },
  {
    mid: '#88fabe',
    darker: '#84b89a',
    lighter: 'rgba(201, 254, 226,0.4)' //'#c9fee2'
  },
  {
    mid: '#ff7b6b',
    darker: '#c48277',
    lighter: 'rgba(255, 204, 177,0.4)' // '#ffccb1'
  },
  {
    mid: '#ff8cc3',
    darker: '#bb7295',
    lighter: 'rgba(252, 208, 239,0.4)' //'#fcd0ef'
  },
  {
    mid: '#ffa600',
    darker: '#c38736',
    lighter: 'rgba(248, 223, 153,0.4)' // '#f8df99'
  },
  {
    mid: '#f07edb',
    darker: '#a9749d',
    lighter: 'rgba(248, 203, 244,0.4)' // '#f8cbf4'
  },
  {
    mid: '#ff8f40',
    darker: '#b07755',
    lighter: 'rgba(249, 214, 161,0.4)' // '#f9d6a1'
  },
  {
    mid: '#488f31',
    darker: '#4c6b40',
    lighter: 'rgba(180, 209, 162,0.4)' // '#b4d1a2'
  },
  {
    mid: '#faf492',
    darker: '#9a9569',
    lighter: 'rgba(252, 251, 202,0.4)' // '#fcfbca'
  },
  {
    mid: '#fab992',
    darker: '#a17e6a',
    lighter: 'rgba(249, 227, 198,0.4)' // '#f9e3c6'
  },
  {
    mid: '#de425b',
    darker: '#924c53',
    lighter: 'rgba(250, 180, 189,0.4)' // '#fab4bd'
  },
  {
    mid: '#35498f',
    darker: '#464b70',
    lighter: 'rgba(175, 177, 210,0.4)' // '#afb1d2'
  },
  {
    mid: '#de4343',
    darker: '#914b46',
    lighter: 'rgba(244, 185, 155,0.4)' // '#f4b99b'
  },
  {
    mid: '#f4afa7',
    darker: '#9b7470',
    lighter: 'rgba(250, 221, 208,0.4)' // '#faddd0'
  },
  {
    mid: '#f1f1f1',
    darker: '#909090',
    lighter: 'rgba(249, 249, 249,0.4)' // '#f9f9f9'
  },
  {
    mid: '#8a53e2',
    darker: '#745b99',
    lighter: 'rgba(224, 180, 238,0.4)' // '#e0b4ee'
  }
];
