import { Close, FullscreenExit, Tune } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { default as MUITooltip } from '@mui/material/Tooltip';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  fetchForecastsByOutcome,
  selectResolutionImpactQuestionsOfOutcome,
  selectedOutcomeBeliefs
} from '../../../store/slices/outcomeSlice';
import { graphColors } from '../GraphColors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

export default function ForecastDashboard({
  outcomeId,
  title,
  forecastingStartDate,
  falseData,
  format,
  setDashboardOpen
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [forecastsStatus, setForecastsStatus] = useState('idle');
  const [stacked, setStacked] = useState(falseData ? false : true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [aggregated, setAggregated] = useState(true);
  const [relative, setRelative] = useState(true);
  const [resolutionImpact, setResolutionImpact] = useState('positive');
  const [summaryCaption, setSummaryCaption] = useState('');
  const [optionsOpen, setOptionsOpen] = useState(false);
  const beliefData = useSelector((state) => {
    return selectedOutcomeBeliefs(state);
  });
  const questions = useSelector(
    (state) =>
      selectResolutionImpactQuestionsOfOutcome(state, outcomeId).filter((q) =>
        beliefData.some((b) =>
          b.data_points.some((d) => d.question_id === q.id)
        )
      ),
    shallowEqual
  );
  const [isQuestionChecked, setIsQuestionChecked] = useState([]);

  const changeStacked = () => {
    setStacked(!stacked);
  };

  const changeFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  const changeOptionsOpen = () => {
    setOptionsOpen(!optionsOpen);
  };

  const changeAggregated = () => {
    setStacked(true);
    setAggregated(!aggregated);
  };

  const changeRelative = () => {
    setRelative(!relative);
  };

  const changeResolutionImpact = () => {
    resolutionImpact === 'positive'
      ? setResolutionImpact('negative')
      : setResolutionImpact('positive');
  };

  const isCheckboxChecked = (index, checked) => {
    setIsQuestionChecked((isQuestionChecked) => {
      return isQuestionChecked.map((c, i) => {
        if (i === index) return checked;
        return c;
      });
    });
  };

  useEffect(() => {
    if (forecastsStatus === 'idle' && !falseData) {
      dispatch(fetchForecastsByOutcome(outcomeId)).then(
        setForecastsStatus('success')
      );
    }
  }, [forecastsStatus, dispatch, outcomeId, falseData]);

  useEffect(() => {
    if (questions) {
      setIsQuestionChecked(questions.map((q) => true));
    }
  }, [questions]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: aggregated ? false : true,
        labels: {
          color: theme.palette.text.primary
        }
      },
      title: {
        display: title !== undefined ? true : false,
        text: title !== undefined ? title : '',
        color: theme.palette.text.secondary,
        font: {
          size: 20
        }
      },
      subtitle: {
        display: false,
        text: summaryCaption,
        color: theme.palette.text.primary,
        font: {
          size: 14
        },
        padding: {
          bottom: 5
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = relative
              ? `True: ${context.parsed.y.toFixed(2)}%`
              : `True: ${context.parsed.y.toFixed(0)}%`;
            return label;
          },
          title: function (context) {
            let title = moment(context[0].parsed.x).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            );
            return title;
          }
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex !== 15000;
        }
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      y: {
        stacked: stacked,
        min: 0,
        max: relative ? 100 : stacked ? questions.length * 100 : 100,
        title: {
          display: format === 'landingpage' ? false : true,
          text: aggregated ? 'Belief Level' : 'Probability',
          color: theme.palette.text.secondary,
          font: {
            size: 20
          }
        },
        ticks: {
          callback: function (value, index, ticks) {
            return relative ? value + '%' : !stacked ? value + '%' : value;
          },
          color: theme.palette.text.primary
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        }
      },
      x: {
        type: 'time',
        time: {
          unit: 'day'
        },
        title: {
          display: false
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        },
        ticks: {
          color: theme.palette.text.primary,
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: 10
        }
      }
    }
  };

  const mapBelief = (belief, question) => {
    for (var i = 0; i < belief.data_points.length; i++) {
      if (belief.data_points[i].question_id === question.id) {
        let probability = belief.data_points[i].forecast_probability * 100;
        if (question.outcome_owner_resolution_impact) {
          probability =
            resolutionImpact === 'positive' ? probability : 100 - probability;
        } else if (question.group_resolution_impact) {
          probability =
            resolutionImpact === 'positive' ? probability : 100 - probability;
        } else if (question.relationship === 'more likely') {
          probability =
            resolutionImpact === 'positive' ? probability : 100 - probability;
        } else {
          probability =
            resolutionImpact === 'positive' ? 100 - probability : probability;
        }
        return relative
          ? (probability / (belief.data_points.length * 100)) * 100
          : probability;
      } else {
        continue;
      }
    }
  };

  const createDatasets = useMemo(() => {
    return questions.map((question, index) => {
      return {
        label:
          question.question_text.length > 30
            ? question.question_text.substring(0, 30) + '...'
            : question.question_text,
        data: beliefData.map((b) => mapBelief(b, question)),
        pointStyle: false,
        borderColor: graphColors[index]?.darker,
        backgroundColor: graphColors[index]?.mid,
        borderWidth: aggregated ? (index === questions.length - 1 ? 3 : 0) : 3,
        fill: {
          target: aggregated
            ? index === questions.length - 1
              ? 'origin'
              : false
            : stacked
            ? index === 0
              ? 'origin'
              : '-1'
            : false,
          above: graphColors[index]?.lighter
        },
        hidden: !isQuestionChecked[index]
      };
    });
  }, [
    beliefData,
    isQuestionChecked,
    questions,
    resolutionImpact,
    stacked,
    aggregated,
    relative
  ]);

  const getLabels = useMemo(() => {
    let labels = beliefData.filter((b) => b.data_points.length > 0);
    labels = labels.map((b) => moment(b.belief_date));
    return labels;
  }, [beliefData, questions, forecastingStartDate]);

  const data = useMemo(
    () =>
      beliefData.length > 0
        ? {
            labels: getLabels,
            datasets: createDatasets
          }
        : falseData
        ? falseData
        : null, // if no data is loaded, use false data. if no false data is provided, return null
    [beliefData, createDatasets, getLabels]
  );

  return data?.labels?.length > 1 ? (
    <div className="flex flex-wrap w-full justify-start">
      <>
        <div
          style={format === 'landingpage' ? { width: '100%' } : {}}
          className="w-full sm:w-11/12 md:w-11/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 h-[22rem]">
          <Line
            style={format === 'landingpage' ? { width: '100%' } : {}}
            options={options}
            data={data}
          />
        </div>
      </>
      {/* do not display collapsible options toggle on landing page */}
      {format !== 'landingpage' && (
        <>
          <div className="flex flex-col">
            <Button
              onClick={() => setDashboardOpen(false)}
              variant="outlined"
              sx={{ mx: 1, alignSelf: 'flex-start' }}>
              <FullscreenExit />
              Minimise
            </Button>
            <Collapse in={!optionsOpen} orientation="vertical">
              <Button
                variant="outlined"
                onClick={changeOptionsOpen}
                sx={{ mx: 1, mt: 1 }}>
                <Tune />
                Options
              </Button>
            </Collapse>
            <Collapse in={optionsOpen} orientation="horizontal">
              <Paper
                sx={{ p: 2 }}
                variant="outlined"
                className="flex flex-col gap-2 mx-2 my-2 h-fit">
                <div className="flex justify-between">
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      mx: 0.5,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}>
                    Advanced Options
                  </Typography>
                  <Button
                    sx={{ p: 0, minWidth: 0, mx: 0.5 }}
                    onClick={changeOptionsOpen}>
                    <Close sx={{ p: 0, minWidth: 0 }} />
                  </Button>
                </div>
                <FormControlLabel
                  control={
                    <ToggleButtonGroup
                      color="primary"
                      value={resolutionImpact}
                      onChange={changeResolutionImpact}>
                      <MUITooltip
                        title="Change the direction of forecasts so that 100% favours your Outcome"
                        value="positive">
                        <ToggleButton size="small" value="positive">
                          Positive
                        </ToggleButton>
                      </MUITooltip>
                      <MUITooltip
                        title="Change the direction of forecasts so that 0% favours your Outcome"
                        value="negative">
                        <ToggleButton size="small" value="negative">
                          Negative
                        </ToggleButton>
                      </MUITooltip>
                    </ToggleButtonGroup>
                  }
                  label={
                    <Typography sx={{ fontSize: '0.875rem' }}>
                      {'Resolution Impact'}
                    </Typography>
                  }
                  labelPlacement="top"
                  sx={{
                    p: 0,
                    m: 0
                  }}
                />
                <Divider sx={{ my: 0.6 }} />
                <MUITooltip
                  title={
                    aggregated
                      ? 'Break the graph down into individual Question forecasts'
                      : 'Combine Question forecasts into one combined metric'
                  }>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={changeAggregated}>
                    {aggregated ? 'Divide' : 'Aggregate'}
                  </Button>
                </MUITooltip>
                <Divider sx={{ my: 0.6 }} />
                <MUITooltip
                  title={
                    relative
                      ? 'Display the actual forecast values'
                      : 'Relate Question forecasts to the outcome on a 0-100 scale'
                  }>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={changeRelative}>
                    {relative ? 'Absolute' : 'Relative'}
                  </Button>
                </MUITooltip>
                <Divider sx={{ my: 0.6 }} />
                {!aggregated && (
                  <>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={changeStacked}>
                      {stacked ? 'Unstack' : 'Stack'}
                    </Button>
                    <Divider sx={{ my: 0.6 }} />
                  </>
                )}
                <MUITooltip title="Select which Questions to include">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={changeFilterOpen}>
                    {filterOpen ? 'Hide' : 'Filters'}
                  </Button>
                </MUITooltip>
              </Paper>
            </Collapse>
          </div>
          <Collapse in={filterOpen} orientation="horizontal">
            <Paper
              sx={{ p: 2, width: '25rem' }}
              variant="outlined"
              className="mx-2 my-2 max-h-72 overflow-y-auto">
              <Typography sx={{ fontWeight: 'bold' }}>Questions</Typography>
              {questions.map((question, index) => {
                return (
                  <div key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            isQuestionChecked[index] !== undefined
                              ? isQuestionChecked[index]
                              : true
                          }
                          onChange={(e) =>
                            isCheckboxChecked(index, e.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.875rem' }}>
                          {question.question_text}
                        </Typography>
                      }
                    />
                    {index !== questions.length - 1 && <Divider />}
                  </div>
                );
              })}
            </Paper>
          </Collapse>
        </>
      )}
    </div>
  ) : null;
}
