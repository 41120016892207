import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import { useEffect, useState } from 'react';

export default function TemporalMeasure({ measure, value, setValue }) {
  useEffect(() => {
    setValue(maxDate);
  }, []);

  const [maxDate] = useState(moment(measure.date_range.max_date).toDate());
  const changevalue = (value) => setValue(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
      <DateField
        label=""
        value={value ? value : maxDate}
        onChange={(newValue) => changevalue(newValue)}
      />
    </LocalizationProvider>
  );
}
