import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import {
  selectSettingById,
  updateSetting
} from '../../store/slices/settingsSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from './InfoTooltip';

export default function SettingCheckboxes({ id }) {
  const setting = useSelector((state) => selectSettingById(state, id));
  const [showChangeConfirm, setShowChangeConfirm] = useState(false);
  const [optionChangeName, setOptionChangeName] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setOptionChangeName(event.target.name);
    setShowChangeConfirm(true);
  };

  let checkboxes;

  if (setting) {
    checkboxes = setting.options.map((option, index) => (
      <div key={index}>
        <Checkbox
          checked={option.selected}
          onChange={(event) => handleChange(event)}
          name={option.name}
        />
        <FormLabel
          sx={{
            fontSize: '0.8rem',
            textTransform: 'capitalize',
            color: 'text.primary'
          }}>
          {option.name}
        </FormLabel>
      </div>
    ));
  } else {
    checkboxes = null;
  }

  return (
    <div>
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="flex items-center justify-end mr-1">
            <FormLabel
              id="settings-checkbox-label"
              sx={{ fontSize: '0.8rem', color: 'text.primary' }}>
              {setting.name}
            </FormLabel>
            <div className="ml-1 pb-1 text-sm">
              <InfoTooltip text={setting.description} />
            </div>
          </div>
          <div className="flex items-center justify-start">
            <FormGroup sx={{ display: 'flex' }} row>
              {checkboxes}
            </FormGroup>
          </div>
        </div>
      </div>
      {showChangeConfirm && (
        <ConfirmationModal
          shown={showChangeConfirm}
          close={() => {
            setShowChangeConfirm(false);
          }}
          confirm={() => {
            setShowChangeConfirm(false);
            dispatch(
              updateSetting({
                id: setting.id,
                toggle_options: optionChangeName,
                auth_token: localStorage.getItem('auth_token')
              })
            );
          }}
          confirmationMessage="Are you sure you want to enable/disable this admin setting? This will modify admin functionality for all users."
        />
      )}
    </div>
  );
}
