import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import { AuthContext } from '../../App';
import {
  fetchOutcomes,
  fetchUserForecastsByOutcome,
  selectOutcomeById,
  setQuestionFilter,
  updateSelectedOutcome
} from '../../store/slices/outcomeSlice';

import OutcomeCardQuestionView from '../../components/cards/OutcomeCardQuestionView';
import QuestionList from '../../components/lists/QuestionList';
import CreateQuestion from '../../components/other/CreateQuestion';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import QuestionStatusFilter from '../../components/other/QuestionStatusFilter';
import CreateStructuredQuestion from '../../components/other/StructuredQuestionForm/CreateStructuredQuestion';
import { selectUserPreferences } from '../../store/slices/userSlice';

export default function OutcomeView() {
  const dispatch = useDispatch();
  const { isLoggedIn, userData } = useContext(AuthContext);
  let { outcomeId } = useParams();
  const location = useLocation();
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const [isVotingDisabled, setIsVotingDisabled] = useState(false);

  const outcome = useSelector((_state) => selectOutcomeById(_state, outcomeId));

  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );
  const [listLoaded, setListLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  useEffect(() => {
    if (outcome) {
      dispatch(updateSelectedOutcome(outcome.id));
    }
  }, [outcome, dispatch]);

  useEffect(() => {
    dispatch(fetchUserForecastsByOutcome(outcomeId));
  }, [dispatch, outcomeId]);
  useEffect(() => {
    if (outcome) {
      let payload;
      payload = {
        outcomeId: outcome.id,
        questionFilter: {
          pending: false,
          accepted: false,
          rejected: false,
          submitted: false,
          notSubmitted: false,
          closed: false,
          duplicate: false
        }
      };

      if (outcome.statuses.includes('Forecasting')) {
        payload.questionFilter.accepted = true;
        payload.questionFilter.submitted = true;
      }
      if (outcome.statuses.includes('Evaluation')) {
        payload.questionFilter.pending = true;
        payload.questionFilter.accepted = true;
      } else if (outcome.statuses.includes('Generation')) {
        payload.questionFilter.pending = true;
        payload.questionFilter.accepted = true;
      } else if (outcome.statuses.includes('Closed')) {
        payload.questionFilter.closed = true;
      }
      if (payload.outcomeId) {
        dispatch(setQuestionFilter(payload));
      }
    }
  }, [outcomeStatus, dispatch]);

  useEffect(() => {
    if (listLoaded) {
      if (location.hash) {
        let str;
        if (location.hash.includes('comment')) {
          let splitArr = location.hash.split('#', location.hash.length);
          splitArr = splitArr[1].split('comment', splitArr[1].length);
          str = 'commentCard' + splitArr[1];
        } else {
          let splitArr = location.hash.split('#', location.hash.length);
          str = 'questionCard' + splitArr[1];
        }
        let elem = document.getElementById(str);
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  }, [location, listLoaded]);

  const getDisplayableFilters = () => {
    if (outcome.forecast_mechanism === 'derived') {
      if (
        outcome.statuses.length === 1 &&
        outcome.statuses[0] === 'Generation'
      ) {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' }
        ];
      } else {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'closed', value: 'Closed' }
        ];
      }
    } else {
      if (outcome.statuses.includes('Generation')) {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      } else if (outcome.statuses.includes('Evaluation')) {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      } else if (outcome.statuses.includes('Forecasting')) {
        return [
          { key: 'submitted', value: 'Submitted' },
          { key: 'notSubmitted', value: 'Not Submitted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' },
          { key: 'closed', value: 'Closed' }
        ];
      } else if (outcome.statuses.includes('Closed')) {
        return [
          { key: 'submitted', value: 'Submitted' },
          { key: 'notSubmitted', value: 'Not Submitted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' },
          { key: 'closed', value: 'Closed' }
        ];
      } else {
        return [];
      }
    }
  };

  let content;
  if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (outcomeStatus === 'succeeded') {
    if (outcome === undefined) {
      content = (
        <p className="text-sm text-center">No questions could be found.</p>
      );
    } else {
      content = (
        <div className="flex justify-center">
          <div
            className={`w-full ${
              userPreferences.question_layout === 'card' ||
              userPreferences.question_layout === 'ranked' ||
              userPreferences.question_layout === undefined
                ? 'lg:w-2/3'
                : 'lg:w-full'
            }`}>
            <OutcomeCardQuestionView outcome={outcome} />
            {outcome.statuses.includes('Generation') &&
              outcome.created_by.id !== userData.id && (
                <div>
                  {userPreferences.question_type === 'freeform' ? (
                    <div className="mx-10">
                      <CreateQuestion outcome={outcome} />
                    </div>
                  ) : (
                    <div className="mx-10">
                      <CreateStructuredQuestion outcome={outcome} />
                    </div>
                  )}
                </div>
              )}
            <QuestionList
              key={outcome.id}
              outcomeId={outcome.id}
              isLoggedIn={isLoggedIn}
              isVotingDisabled={isVotingDisabled}
              setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
              setListLoaded={setListLoaded}
            />
          </div>
        </div>
      );
    }
  } else {
    content = (
      <p className="text-sm text-center">No questions could be found.</p>
    );
  }

  return (
    <div className="QuestionsView mt-5">
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
        <div className="ml-10 items-center">
          <HierarchicalBreadcrumbs outcomes={true} outcome={true} />
        </div>
        {outcome && userPreferences.question_layout === 'detail' && (
          <QuestionStatusFilter
            filters={getDisplayableFilters()}
            outcomeId={outcome.id}
            className="mx-5"
          />
        )}
      </div>
      {outcome &&
        (userPreferences.question_layout === 'card' ||
          userPreferences.question_layout === 'ranked' ||
          userPreferences.question_layout === undefined) && (
          <div className="flex justify-center">
            <div className="w-full md:w-2/3">
              <div className="w-full flex justify-end">
                <QuestionStatusFilter
                  filters={getDisplayableFilters()}
                  outcomeId={outcome.id}
                  className="mx-5"
                />
              </div>
            </div>
          </div>
        )}

      <section className="question-view">{content}</section>
    </div>
  );
}
