import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SmallQuestionCard from '../cards/SmallQuestionCard';

export default function QuestionsTable({ questions }) {
    return (
      <TableContainer component={Paper} className='overflow-scroll' sx={{
        height: '40vh',
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Questions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {questions && questions.map((question, index) => (
            <TableRow key={index}>
                <TableCell>
                    {/* <Typography
                        onClick={() => {
                            navigate(`/questions/${question.id}`);
                        }}
                        sx={{ mx: 0.6, fontSize: '0.95rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {question.question_text}
                    </Typography> */}
                    <SmallQuestionCard questionId={question.id}/>
                </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
            }