import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import { checkTokenStatus } from '../../../App';
import {
  addNewForecast,
  confirmForecastsComplete,
  selectForecastsByQuestion
} from '../../../store/slices/outcomeSlice';
import ForecastCard from '../../cards/ForecastCard';

import moment from 'moment';

import { FaChartArea } from 'react-icons/fa';
import { MdSpeed } from 'react-icons/md';

import {
  Button,
  Card,
  Input,
  InputAdornment,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { RiCalendarCheckFill, RiQuestionnaireFill } from 'react-icons/ri';
import ConfirmationModal from '../../modals/ConfirmationModal';
import InfoTooltip from '../../other/InfoTooltip';

export default function QuestionContainer({
  question,
  forecastingStartDate,
  maxForecastingWeeks
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const forecasts = useSelector((state) =>
    selectForecastsByQuestion(state, question.id)
  );
  const [probability, setProbability] = useState(0);
  const [addForecastRequestStatus, setAddForecastRequestStatus] =
    useState('idle');
  const [completeForecastsRequestStatus, setCompleteForecastsRequestStatus] =
    useState('idle');

  const [forecastDate, setForecastDate] = useState(new Date());
  const [showCompleteConfirm, setShowCompleteConfirm] = useState(false);

  const canSetForecastScore =
    [question.id, forecastDate, checkTokenStatus()].every(Boolean) &&
    probability <= 100 &&
    probability >= 0 &&
    addForecastRequestStatus === 'idle';
  const addForecast = async () => {
    if (canSetForecastScore) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setAddForecastRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          probability: Math.floor(probability) / 100,
          questionId: question.id,
          forecast_date: forecastDate,
          auth_token: token
        };
        await dispatch(addNewForecast(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setProbability(0);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to set add question forecast: ${err.message}`);
      } finally {
        if (isMounted) {
          setAddForecastRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus()) {
      setErrorMessage(`Failed to set add question forecast: not logged in.`);
    } else {
      setErrorMessage(
        `Failed to set add question forecast: missing required parameter or invalid value.`
      );
    }
  };

  const confirmComplete = async () => {
    if (
      question.id &&
      checkTokenStatus() &&
      completeForecastsRequestStatus === 'idle'
    ) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setCompleteForecastsRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          questionId: question.id,
          auth_token: token
        };
        await dispatch(confirmForecastsComplete(payload));
      } catch (err) {
        setErrorMessage(
          `Failed to confirm completeion of question's forecasts: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setCompleteForecastsRequestStatus('idle');
          isMounted = false;
        }
      }
    } else {
      setErrorMessage(
        `Failed to confirm completion of question's forecasts: missing question ID or not logged in.`
      );
    }
  };

  const handleSliderChange = (event, newValue) => {
    setProbability(newValue);
  };

  const handleInputChange = (event) => {
    setProbability(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (probability < 0) {
      setProbability(0);
    } else if (probability > 100) {
      setProbability(100);
    }
  };

  const getStatusColour = () => {
    switch (question.status) {
      case 'Pending':
        return theme.palette.statuses.mid1;
      case 'Rejected':
        return theme.palette.statuses.mid5;
      case 'Not Submitted':
        return theme.palette.statuses.mid2;
      case 'Accepted':
        return theme.palette.statuses.mid3;
      case 'Submitted':
        return theme.palette.statuses.mid3;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Duplicate':
        return theme.palette.statuses.mid6;
      default:
        return theme.palette.statuses.mid7;
    }
  };

  let forecastList = [];
  let completedWeeks = [];
  for (let i = 0; i < forecasts.length; i++) {
    forecastList.push(
      <ForecastCard
        key={i}
        forecast={forecasts[i]}
      />
    );
  }

  return (
    <Card
      sx={{
        pt: 1,
        pb: 1.4,
        px: 2,
        m: 0.2,
        mb: 1.2,
        borderRadius: '0.5rem',
        backgroundColor: 'background.default'
      }}
      className="rounded-lg shadow-lg">
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
        <RiQuestionnaireFill className="mr-1" /> Question
      </Typography>
      <div className="flex items-center max-h-36 overflow-y-auto">
        <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
          <span className="whitespace-pre-line">{question.question_text}</span>
        </Typography>
        <Typography
          sx={{ fontSize: '0.875rem', ml: 1.2, mr: 0.6 }}
          color="text.secondary">
          Created by{' '}
        </Typography>
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/profile/${question.created_by.username}`);
          }}
          sx={{ fontSize: '0.875rem', mr: 1.2 }}
          className="hover:underline hover:cursor-pointer"
          color="primary.main">
          {question.created_by.username}
        </Typography>
        <Typography
          sx={{
            borderColor: getStatusColour(),
            backgroundColor: getStatusColour(),
            py: 0,
            px: 0.3,
            fontSize: '0.85rem',
            mx: 0.6
          }}
          className={`border-2 rounded text-white font-normal text-xs px-1`}>
          {question.status}
        </Typography>
      </div>
      {/* {forecasts.length > 0 && */}
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
          mt: 0.6,
          ml: 1.8
        }}>
        <FaChartArea className="mr-1" /> Forecasts
      </Typography>
      <div className="px-3 mt-1">
        <TableContainer
          component={Paper}
          sx={{ overflowY: 'auto', maxHeight: '14rem' }}>
          <Table sx={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: 'black',
                    py: 1,
                    fontWeight: 600,
                    color: 'white'
                  }}
                  align="left">
                  Forecast Date
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: 'black',
                    py: 1,
                    fontWeight: 600,
                    color: 'white'
                  }}>
                  Probability
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{forecastList}</TableBody>
          </Table>
        </TableContainer>
      </div>
      {completedWeeks.length === maxForecastingWeeks && (
        <Card className="text-center mx-3 p-1">
          <Button
            sx={{ my: 0.6 }}
            variant="contained"
            onClick={() => setShowCompleteConfirm(true)}
            className="inline-flex items-center">
            <RiCalendarCheckFill className="mr-1" /> Complete
          </Button>
        </Card>
      )}
      <Card sx={{ mx: 1.8, display: 'flex', pb: 0.6 }}>
        <div className="w-fit lg:w-1/4 m-1 flex">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={ukLocale}>
            <DatePicker
              clearable
              label="Forecast date"
              value={forecastDate}
              onChange={(newValue) => {
                setForecastDate(newValue);
              }}
              minDate={parseISO(forecastingStartDate)} // cannot submit forecast values for dates that are before forecasting started
              maxDate={moment().toDate()}              // cannot submit forecast values for dates in the future
              textField={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div className="ml-1">
            <InfoTooltip text="The effective date of the forecast (replaces forecast week and forecast day)." />
          </div>
        </div>
        <div className="flex flex-col basis-5/12 items-center h-full">
          <Typography className="mr-2 font-semibold mb-1">
            Probability
          </Typography>
          <div className="flex items-center justify-center w-full">
            <div className="mx-2">
              <MdSpeed size={37} />
            </div>
            <div className="w-full mx-2 items-center">
              <Slider
                value={typeof probability === 'number' ? probability : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
              />
            </div>
            <div className="mx-2">
              <Input
                value={probability}
                size="small"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>
          </div>
        </div>
        <div className="flex basis-4/12 items-center justify-center">
          <Button variant="contained" onClick={addForecast}>
            Add
          </Button>
        </div>
      </Card>
      {errorMessage && (
        <p className="text-xs text-red-600 py-2">{errorMessage}</p>
      )}
      {showCompleteConfirm && (
        <ConfirmationModal
          shown={showCompleteConfirm}
          close={() => {
            setShowCompleteConfirm(false);
          }}
          confirm={() => {
            setShowCompleteConfirm(false);
            confirmComplete(); //Change to confirm complete
          }}
          confirmationMessage="Do you want to confirm that all forecasts for the selected question are complete? This cannot be undone."
        />
      )}
    </Card>
  );
}
