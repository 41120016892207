import React, { useContext, useState } from 'react';

import { AuthContext } from '../../App';

import { useSelector } from 'react-redux';
import ActivitySummary from '../../components/other/ActivitySummary';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import { selectUserById } from '../../store/slices/userSlice';

import { CalendarMonthOutlined, Groups, MailOutline } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  Collapse,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';

import { BiChevronDownCircle, BiChevronUpCircle, BiKey } from 'react-icons/bi';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import InfoTooltip from '../other/InfoTooltip';
import ResetUserPasswordModal from '../modals/ResetUserPasswordModal';

export default function ProfileCard({ userId }) {
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );
  const ownScoreSettings = useSelector((state) =>
    selectSettingByName(state, 'View Own Accuracy Scores')
  );
  const othersScoreSettings = useSelector((state) =>
    selectSettingByName(state, 'View Other Users Scores')
  );
  const { userData } = useContext(AuthContext);
  const totalUsers = useSelector((state) => state.users.totalUsers);
  const user = useSelector((state) => selectUserById(state, userId));
  const [collapsed, setCollapsed] = useState(true);
  const [showResetModal, setShowResetModal] = useState(false);

  const scoreVisibility =
    userData.username === user.username
      ? ownScoreSettings.active ||
        userData.role === 'Admin' ||
        userData.role === 'Moderator'
      : othersScoreSettings.active ||
        userData.role === 'Admin' ||
        userData.role === 'Moderator';
  const passwordReset = userData.role === 'Admin' && userData.username !== user.username

  const roundDownNearest10 = (num) => {
    if (num < 5) {
      return 5;
    } else {
      return Math.ceil(num / 10) * 10;
    }
  };

  return (
    <div className="ProfileCard w-full p-2">
      {showResetModal && userData.role === 'Admin' && (
        <ResetUserPasswordModal
          shown={showResetModal}
          close={() => {
            setShowResetModal(false);
          }}
          username = {user.username}
        />
      )}
      {user &&
      (usernameSettings.active ||
        userData.role === 'Admin' ||
        userData.role === 'Moderator') ? (
        <div className="w-full">
          <div className="flex p-1 items-center justify-end flex-col">
            <Card sx={{ p: 2, m: 1, borderRadius: '0.5rem' }}>
              <div className="flex justify-center p-1">
                <Avatar
                  sx={{ textAlign: 'center', height: '65px', width: '65px' }}
                />
              </div>
              <div className="flex justify-center items-center p-1">
                <Typography
                  sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    fontSize: '1.1rem'
                  }}>
                  {user.username}
                </Typography>
                <div className="pb-1">
                  <RoleBadgeIcon role={user.role} />
                </div>
              </div>
              {user.groups?.length > 0 && (
                <div className="flex text-sm items-center">
                  <Groups fontSize="inherit" sx={{ mr: 0.6 }} />
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: '0.95rem',
                    }}
                  >
                    <span className="font-bold"></span>{user.groups.map((group, index) => (
                      <span key={group.id}>
                        {index > 0 ? ', ' : ''}
                        {group.name}
                      </span>
                    ))}
                  </Typography>
                </div>
              )}
              {user.email && (
                <div className="flex text-sm items-center">
                  <MailOutline fontSize="inherit" sx={{ mr: 0.6 }} />
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: '0.95rem'
                    }}>
                    <span className="font-bold"></span> {user.email}
                  </Typography>
                </div>
              )}
              <div className="flex text-sm items-center">
                <CalendarMonthOutlined fontSize="inherit" sx={{ mr: 0.6 }} />
                <Typography
                  color="text.secondary"
                  sx={{
                    fontSize: '0.95rem'
                  }}>
                  <span>Joined </span>{' '}
                  {moment(user.registered_on).format('MMMM Do YYYY')}
                </Typography>
              </div>
              <Divider sx={{ my: 0.6 }} />
              {scoreVisibility && (
                <>
                  <div className="flex">
                    <Typography>
                      <span className="font-bold">
                        Confirmed Generator Score:{' '}
                      </span>
                      {(user.confirmed_generator_score * 100).toFixed(2)}{' '}
                      {user.confirmed_generator_rank
                        ? '(top ' +
                          roundDownNearest10(
                            (user.confirmed_generator_rank / totalUsers) * 100
                          ) +
                          '%)'
                        : ''}
                    </Typography>
                    <div className="ml-1">
                      <InfoTooltip text="The average score achieved by Questions submitted by the user. Taken only from closed questions. Higher is better." />
                    </div>
                  </div>
                  <div className="flex">
                    <Typography>
                      <span className="font-bold">
                        Confirmed Evaluator Score:{' '}
                      </span>
                      {user.confirmed_evaluator_score}{' '}
                      {user.confirmed_evaluator_rank
                        ? '(top ' +
                          roundDownNearest10(
                            (user.confirmed_evaluator_rank / totalUsers) * 100
                          ) +
                          '%)'
                        : ''}
                    </Typography>
                    <div className="ml-1">
                      <InfoTooltip text="The average accuracy of the user’s evaluations of Question scores, compared to subsequently achieved scores. Taken only from closed questions. Higher is better. 4 is the maximum." />
                    </div>
                  </div>
                  <Collapse className="w-full" in={!collapsed}>
                    <div className="flex">
                      <Typography>
                        <span className="font-bold">
                          Total Generator Score:{' '}
                        </span>
                        {(user.total_generator_score * 100).toFixed(2)}{' '}
                        {user.total_generator_rank
                          ? '(top ' +
                            roundDownNearest10(
                              (user.total_generator_rank / totalUsers) * 100
                            ) +
                            '%)'
                          : ''}
                      </Typography>
                      <div className="ml-1">
                        <InfoTooltip text="The total of confirmed question scores by this user. Higher is better." />
                      </div>
                    </div>
                    <div className="flex">
                      <Typography>
                        <span className="font-bold">
                          Current Generator Score:{' '}
                        </span>
                        {(user.current_generator_score * 100).toFixed(2)}{' '}
                        {user.current_generator_rank
                          ? '(top ' +
                            roundDownNearest10(
                              (user.current_generator_rank / totalUsers) * 100
                            ) +
                            '%)'
                          : ''}
                      </Typography>
                      <div className="ml-1">
                        <InfoTooltip text="The average score achieved by Questions submitted by the user. Taken from all questions including incomplete questions. Higher is better." />
                      </div>
                    </div>
                    <div className="flex">
                      <Typography>
                        <span className="font-bold">
                          Current Evaluator Score:{' '}
                        </span>
                        {user.current_evaluator_score}{' '}
                        {user.current_evaluator_rank
                          ? '(top ' +
                            roundDownNearest10(
                              (user.current_evaluator_rank / totalUsers) * 100
                            ) +
                            '%)'
                          : ''}
                      </Typography>
                      <div className="ml-1">
                        <InfoTooltip text="The average accuracy of the user’s evaluations of Question scores, compared to the current achieved scores. Taken from all questions including incomplete questions. Higher is better. 4 is the maximum." />
                      </div>
                    </div>

                    <div className="flex">
                      <Typography>
                        <span className="font-bold">Resolvability Rate: </span>
                        {(user.resolvability_rate * 100).toFixed(2)}%
                      </Typography>
                      <div className="ml-1">
                        <InfoTooltip text="The percentage of questions posted by this user that are marked as resolvable." />
                      </div>
                    </div>
                    <div className="flex">
                      <Typography>
                        <span className="font-bold">Relevance Rate: </span>
                        {(user.relevance_rate * 100).toFixed(2)}%
                      </Typography>
                      <div className="ml-1">
                        <InfoTooltip text="The percentage of questions posted by this user that are marked as relevant." />
                      </div>
                    </div>
                  </Collapse>
                  <div className="w-full flex justify-center mt-1">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ textTransform: 'none', p: 0.2 }}
                      className="w-32 "
                      onClick={() => setCollapsed(!collapsed)}>
                      <div className="flex justify-center items-center">
                        {collapsed ? (
                          <BiChevronDownCircle className="mr-2" />
                        ) : (
                          <BiChevronUpCircle className="mr-2" />
                        )}
                        <span>{collapsed ? 'Show more' : 'Show less'}</span>
                      </div>
                    </Button>
                  </div>
                </>
              )}
              {passwordReset && <div className="w-full flex justify-center mt-1">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ textTransform: 'none', p: 0.2, bgcolor: theme.palette.statuses.mid5, ':hover': {bgcolor: '#e64400'}}}
                      className="w-32"
                      onClick={() => setShowResetModal(true)}>
                      <div className="flex justify-center items-center">
                        <BiKey className="mr-1" />
                        <span>Reset Password</span>
                      </div>
                    </Button>
                  </div>}
            </Card>
          </div>
          <div className="flex justify-center m-3">
            <div className="flex-initial w-full lg:w-4/5">
              <ActivitySummary userId={user.id} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          {!user ? (
            <Typography className="text-sm text-center">
              A user with this username could not be found.
            </Typography>
          ) : (
            <Typography className="text-sm text-center">
              User visibility has been disabled and so the profile cannot be
              shown.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
