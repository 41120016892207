import React from "react";
import { Typography, useTheme } from "@mui/material";
import NavigationGrid from "../../components/other/NavigationGrid";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HierarchicalBreadcrumbs from "../../components/other/HierarchicalBreadcrumbs";

export default function OutcomeOwnerNavigationView() {
  const isDarkMode = useTheme().palette.mode === 'dark'
  const navigationItems = [
    {
      icon: {
        component: <AddBoxIcon style={{ color: "white"}} fontSize="large" sx={{scale:"1.25"}} />,
        color: isDarkMode ? "bg-gradient-to-br from-cyan-600 to-blue-800" : "bg-gradient-to-br from-blue-400 to-cyan-600",
      },
      title: "Create an Outcome",
      description: "Add new outcomes to the platform.",
      link: "/submit",
    },
    {
      icon: {
        component: <AccountBoxIcon style={{ color: "white"}} fontSize="large" sx={{scale:"1.25"}} />,
        color: isDarkMode ? "bg-gradient-to-br from-amber-600 to-yellow-800" : "bg-gradient-to-br from-amber-400 to-yellow-600"
      },
      title: "My Outcomes",
      description: "Explore your created outcome.",
      link: "/outcomes?filter=own",
    },
    {
      icon: {
        component: <InsertChartIcon style={{ color: "white"}} fontSize="large" sx={{scale:"1.25"}} />,
        color: isDarkMode ? "bg-gradient-to-br from-lime-600 to-green-800" : "bg-gradient-to-br from-lime-400 to-green-600" 
      },
      title: "User Performance",
      description: "Explore user performance.",
      link: "/leaderboard",
    },
  ];

  return (
    <div className="OutcomeOwnerNavigationView">
      <div className="flex justify-between ml-5 mt-7 items-center">
        <HierarchicalBreadcrumbs quickActions={true} />
      </div>
      <div className="flex flex-col relative shrink-0 box-border items-center pt-5 pb-12 px-5">
        <div className="box-border mt-3 mx-auto">
          <Typography variant="h4" sx={{mb:2}}>
            <strong>Quick Actions</strong>
          </Typography>
        </div>
        <NavigationGrid navigationItems={navigationItems} />
      </div>
    </div>
  );
}
