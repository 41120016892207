import { useTheme } from '@emotion/react';
import React from 'react';

export default function LandingPageEvaluation() {
  const theme = useTheme();
  return (
    <svg
      role="img"
      title="Icon of a speaker presenting a line graph to a crowd"
      id="Pictogram_artwork"
      data-name="Pictogram artwork"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63 63"
      style={{
        fill: theme.palette.mode === 'dark' ? 'white' : 'black',
        position: 'absolute',
        top: '21%',
        left: '21%',
        width: '55%',
        height: '55%'
      }}>
      <path d="m15.09,48.88c0,.55.45,1,.99,1s.99-.45.99-1c0-1.56,2.27-2.87,4.97-2.87s4.97,1.31,4.97,2.87c0,.55.45,1,.99,1h0c.55,0,.99-.45.99-1,0-1.56,2.28-2.87,4.97-2.87s4.97,1.31,4.97,2.87c0,.55.45,1,.99,1h0c.55,0,.99-.45.99-1,0-1.56,2.27-2.87,4.97-2.87s4.97,1.31,4.97,2.87c0,.55.45,1,.99,1s.99-.45.99-1c0-2.06-1.73-3.79-4.26-4.51,1.15-1,1.81-2.46,1.8-4,.11-2.62-1.9-4.84-4.5-4.99-2.61.15-4.61,2.37-4.5,4.99-.01,1.53.65,3,1.81,4-1.26.33-2.39,1.03-3.26,2-.87-.98-2.02-1.67-3.28-2,1.16-1,1.81-2.46,1.8-4,.11-2.62-1.89-4.84-4.49-4.99-2.61.15-4.61,2.37-4.5,4.99-.01,1.53.65,3,1.81,4-1.25.33-2.38,1.03-3.25,2-.88-.97-2.02-1.66-3.28-2,1.15-1,1.81-2.46,1.8-4,.11-2.62-1.9-4.84-4.5-4.99-2.61.15-4.61,2.37-4.5,4.99-.01,1.53.65,3,1.81,4-2.52.74-4.27,2.45-4.27,4.51h0Zm30.82-11.55c1.51.15,2.62,1.48,2.52,3,.15,1.55-.97,2.93-2.52,3.09-1.52-.16-2.64-1.51-2.52-3.05-.13-1.53.99-2.88,2.52-3.04h0Zm-11.93,0c1.5.16,2.61,1.48,2.51,3,.15,1.55-.97,2.92-2.51,3.09-1.52-.16-2.64-1.51-2.52-3.05-.13-1.53.99-2.88,2.52-3.04h0Zm-11.93,0c1.51.15,2.62,1.48,2.52,3,.15,1.55-.97,2.93-2.52,3.09-1.52-.16-2.64-1.51-2.52-3.05-.13-1.53.99-2.88,2.52-3.04h0Z" />
      <path d="m28.02,30.98c0-.55-.45-1-.99-1h-.99c.04-.09.05-.18.05-.28v-2.94c0-2.05-1.72-3.77-4.24-4.5,1.16-1,1.82-2.46,1.81-4,.11-2.62-1.9-4.84-4.5-4.99-2.61.15-4.61,2.37-4.5,4.99-.01,1.53.64,2.99,1.8,4-2.6.72-4.34,2.45-4.34,4.51,0,.55.45,1,.99,1s.99-.45.99-1c0-1.56,2.28-2.87,4.97-2.87s4.97,1.31,4.97,2.88v2.94c.01.09.03.18.06.27h-12.99c-.55,0-.99.45-.99,1v5.91c0,.55.45,1,.99,1s.99-.45.99-1v-4.92h14.91c.55,0,.99-.45.99-1h0Zm-8.95-15.75c1.51.15,2.62,1.48,2.52,3,.15,1.55-.97,2.93-2.52,3.09-1.52-.16-2.64-1.51-2.52-3.05-.13-1.53.99-2.88,2.52-3.04h0Z" />
      <path d="m52.88,16.99c0-1.66-1.34-3-2.98-3h-16.9c-1.65,0-2.98,1.34-2.98,3v9.99c0,.55.45,1,.99,1s.99-.45.99-1v-9.99c0-.55.45-1,.99-1h16.95c.25,0,.49.11.67.28.19.19.3.45.28.72v11.99c0,.55-.45,1-.99,1h-18.89c-.55,0-.99.45-.99,1s.45,1,.99,1h18.89c1.65,0,2.98-1.34,2.98-3v-11.99Z" />
      <path d="m36.5,26.51l2.68-2.7,3.57.9c.25.06.52-.01.71-.2l3.98-4c.28-.3.26-.78-.04-1.06-.29-.27-.73-.27-1.02,0l-3.68,3.7-3.57-.9c-.25-.06-.52.01-.71.2l-2.98,3c-.29.29-.29.77,0,1.06.29.29.76.29,1.05,0h0Z" />
    </svg>
  );
}
