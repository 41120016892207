import { useTheme } from '@emotion/react';
import React from 'react';

export default function LandingPageForecasting() {
  const theme = useTheme();
  return (
    <svg
      role="img"
      title="Icon of a line graph"
      id="Pictogram_artwork"
      data-name="Pictogram artwork"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63 63"
      style={{
        fill: theme.palette.mode === 'dark' ? 'white' : 'black',
        position: 'absolute',
        top: '21%',
        left: '21%',
        width: '55%',
        height: '55%'
      }}>
      <path d="m48.44,48.37H13.68c-.55,0-1-.45-1-1v-30.11c0-.55.45-1,1-1s1,.45,1,1v29.11h33.76c.55,0,1,.45,1,1s-.45,1-1,1Z" />
      <path d="m21.86,42.12c-2.19-.01-3.98-1.8-3.99-3.99,0-2.2,1.79-4,3.99-4.01h0c2.21,0,4,1.79,4,4-.01,2.21-1.81,4-4.01,4Zm.01-6c-1.1,0-2,.91-2,2.01,0,1.09.9,1.99,2,1.99,1.1,0,2-.9,2-2,0-1.1-.9-1.99-2-2Z" />
      <path d="m29.24,33.76c-2.19-.01-3.98-1.8-3.99-3.99,0-2.2,1.79-4,3.99-4.01h0c2.21,0,4,1.79,4,4-.01,2.21-1.81,4-4.01,4Zm.01-6c-1.1,0-2,.91-2,2.01,0,1.09.9,1.99,2,1.99,1.1,0,2-.9,2-2,0-1.1-.9-1.99-2-1.99Z" />
      <path d="m39.24,39.12c-2.19-.01-3.98-1.8-3.99-3.99,0-2.2,1.79-4,3.99-4.01h0c2.21,0,4,1.79,4,4-.01,2.21-1.81,4-4.01,4Zm.01-6c-1.1,0-2,.91-2,2.01,0,1.09.9,1.99,2,1.99,1.1,0,2-.9,2-2,0-1.1-.9-1.99-2-1.99Z" />
      <path d="m44.88,28.44c-2.19-.01-3.98-1.8-3.99-3.99,0-2.2,1.79-4,3.99-4.01h0c2.21,0,4,1.79,4,4-.01,2.21-1.81,4-4.01,4Zm.01-6c-1.1,0-2,.91-2,2.01,0,1.09.9,1.99,2,1.99,1.1,0,2-.9,2-2,0-1.1-.9-1.99-2-1.99Z" />
      <rect
        x="23.1"
        y="33.05"
        width="5.19"
        height="2"
        transform="translate(-16.86 31.03) rotate(-48.9)"
      />
      <rect
        x="33.19"
        y="29.88"
        width="2"
        height="5.38"
        transform="translate(-11.12 45.86) rotate(-59.96)"
      />
      <rect
        x="39.21"
        y="28.88"
        width="6.1"
        height="2"
        transform="translate(-4.07 52.96) rotate(-61.73)"
      />
    </svg>
  );
}
