import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';

function StyledSlider(props) {
  const theme = useTheme();
  const {
    value,
    setValue,
    min,
    max,
    marks,
    step,
    style,
    workflowLabels,
    ...rest
  } = props;
  const [workflowLabelValues, setworkflowLabelValues] = useState([
    (min + value[0]) / 2,
    (value[0] + value[1]) / 2,
    (value[1] + max) / 2
  ]);
  const [perc, setPerc] = useState(
    value.map((val) => ((val - min) / (max - min)) * 100)
  );
  const minEvalPhase = 86400000;
  const custom = { year: 'numeric', month: 'short', day: 'numeric' };

  useEffect(() => {
    setPerc(value.map((val) => ((val - min) / (max - min)) * 100));
    updateWorkflowLabels();
  }, [max, min]);

  const updateWorkflowLabels = () => {
    var labelValues = [
      (min + value[0]) / 2,
      (value[0] + value[1]) / 2,
      (value[1] + max) / 2
    ];
    for (var i = 0; i < labelValues.length; i++) {
      if (labelValues[i] === min) labelValues[i] += 1;
      if (labelValues[i] >= max) labelValues[i] = max - 1;
    }
    setworkflowLabelValues(labelValues);
  };

  const onChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minEvalPhase), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minEvalPhase)]);
    }
    updateWorkflowLabels();
    setPerc(newValue.map((val) => ((val - min) / (max - min)) * 100));
  };
  function valueText(value) {
    let totalMS = Number(value);
    return new Date(totalMS).toLocaleDateString('en-gb', custom);
  }

  const getStatusColours = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: '16px'
      }}>
      <Slider
        sx={{
          '& .MuiSlider-track': {
            visibility: 'hidden',
            background: 'yellow',
            borderColor: 'white',
            height: 140,
            '&:before': {
              top: '50%',
              borderTop: '1px solid black',
              transform: 'translateY(-50%)',
              left: 0
            }
          },
          // '& .MuiSlider-markLabelActive': {
          //   color: '#9e9e9e'
          // },
          '& .MuiSlider-thumb': {
            height: 140,
            width: 13,
            borderColor: 'black',
            border: 1,
            borderRadius: 4,
            [`&:nth-of-type(${1}n)`]: {
              background: theme.palette.statuses.mid1,
              '& span': {
                background: theme.palette.statuses.mid1
              }
            },
            [`&:nth-of-type(${2}n)`]: {
              background: theme.palette.statuses.dark3,
              '& span': {
                background: theme.palette.statuses.dark3
              }
            }
          },
          '& .MuiSlider-mark': {
            backgroundColor: 'black',
            height: 15,
            width: '0.8px'
          },
          '& .MuiSlider-mark[data-index="0"]': {
            background: 'none'
          },
          '& .MuiSlider-mark[data-index="1"]': {
            background: 'none'
          },
          '& .MuiSlider-mark[data-index="2"]': {
            background: 'none'
          },
          '& .MuiSlider-mark[data-index="3"]': {
            transform: 'translate(0%, 365%)'
          },
          '& .MuiSlider-mark[data-index="4"]': {
            transform: 'translate(-50%, -465%)'
          },
          '& .MuiSlider-mark[data-index="5"]': {
            transform: 'translate(-50%, 365%)'
          },
          '& .MuiSlider-mark[data-index="6"]': {
            transform: 'translate(-50%, -465%)'
          },
          '& .MuiSlider-mark[data-index="7"]': {
            transform: 'translate(-50%, 365%)'
          },
          '& .MuiSlider-mark[data-index="8"]': {
            transform: 'translate(-50%, -465%)'
          },
          '& .MuiSlider-mark[data-index="9"]': {
            transform: 'translate(-50%, 365%)'
          },
          '& .MuiSlider-mark[data-index="10"]': {
            transform: 'translate(-50%, -465%)'
          },
          '& .MuiSlider-mark[data-index="11"]': {
            transform: 'translate(-50%, 365%)'
          },
          '& .MuiSlider-markLabel': {
            zIndex: 2,
            color: theme.palette.text.primary
          },
          '& .MuiSlider-markLabel[data-index="0"]': {
            transform: 'translate(-50%, -106%)',
            fontWeight: 'bold',
            fontSize: '1rem'
          },
          '& .MuiSlider-markLabel[data-index="1"]': {
            transform: 'translate(-50%, -106%)',
            fontWeight: 'bold',
            fontSize: '1rem'
          },
          '& .MuiSlider-markLabel[data-index="2"]': {
            transform: 'translate(-50%, -106%)',
            fontWeight: 'bold',
            fontSize: '1rem'
          },
          '& .MuiSlider-markLabel[data-index="4"]': {
            transform: 'translate(-50%, -240%)'
          },
          '& .MuiSlider-markLabel[data-index="6"]': {
            transform: 'translate(-50%, -240%)'
          },
          '& .MuiSlider-markLabel[data-index="8"]': {
            transform: 'translate(-50%, -240%)'
          },
          '& .MuiSlider-markLabel[data-index="10"]': {
            transform: 'translate(-50%, -240%)'
          },
          '& .MuiSlider-rail': {
            height: 140,
            borderRadius: 1,
            background: `linear-gradient(to right, ${getStatusColours(
              'Generation'
            )} 0% ${perc[0]}%, ${getStatusColours('Evaluation')} ${perc[0]}% ${
              perc[1]
            }%, ${getStatusColours('Forecasting')} ${perc[1]}% 100%)`
          },

          ...style
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={valueText}
        value={value}
        min={min}
        max={max}
        step={step}
        marks={[
          ...workflowLabelValues.map((val, idx) => ({
            value: val,
            label: workflowLabels[idx]
          })),
          {
            key: 0,
            value: min,
            label: new Date(min).toLocaleDateString('en-gb', custom)
          },
          ...marks
        ]}
        onChange={onChange}
        disableSwap
        getAriaValueText={valueText}
        {...rest}></Slider>
    </Box>
  );
}

export default function TimelineSlider({
  generationEndDate,
  setGenerationEndDate,
  forecastingStartDate,
  setForecastingStartDate,
  outcomeEndDate,
  setOutcomeEndDate
}) {
  const initialiseMin = () => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    return date.getTime();
  };
  const [min, setMin] = useState(initialiseMin());

  const custom = { year: 'numeric', month: 'short', day: 'numeric' };

  const intialiseMarks = () => {
    let labels = [];
    for (let i = 1; i < 8; i++) {
      labels.push({
        value: min + ((outcomeEndDate - min) / 7) * i,
        label: new Date(
          min + ((outcomeEndDate - min) / 7) * i
        ).toLocaleDateString('en-gb', custom)
      });
    }
    return labels;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledSlider
        min={min}
        max={outcomeEndDate}
        marks={intialiseMarks()}
        step={86400000}
        value={[generationEndDate, forecastingStartDate]}
        setValue={(newValue) => {
          setGenerationEndDate(newValue[0]);
          setForecastingStartDate(newValue[1]);
        }}
        workflowLabels={['Generation', 'Evaluation', 'Forecasting']}
      />
    </Box>
  );
}
