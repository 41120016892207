import React from "react";
import { Typography, Button, Grid, Card } from "@mui/material";
import { Link } from "react-router-dom";

function NavigationCard({ icon, title, description, link, xs=12, md=4}) {
  return (
    <Grid item xs={xs} md={md}>
      <Link to={link}>
        <Card elevation={3} className="flex flex-col items-center justify-center pb-4">
            <div
            className={`${icon.color} w-32 h-32 mt-3 rounded-full flex items-center justify-center`}
            >
            {icon.component}
            </div>
            <div className="mt-3 mx-auto flex text-center">
            <Typography variant="h5">
                <strong>{title}</strong>
            </Typography>
            </div>
            <div className="font-thin text-center mb-4">
            <Typography variant="body1">{description}</Typography>
            </div>
            <Button variant="outlined">Explore</Button>
        </Card>
      </Link>
    </Grid>
  );
}

export default function NavigationGrid({navigationItems}) {
  return (
    <div className="w-full xl:w-2/3">
      <Grid container spacing={5}>
        {navigationItems.map((item, index) => (
          <NavigationCard key={index} {...item} />
        ))}
      </Grid>
    </div>
  );
}
