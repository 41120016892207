import { createAsyncThunk } from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

export const uploadData = createAsyncThunk(
  'admin/uploadData',
  async (payload) => {
    const response = await hivemindAPI.postFile(
      '/api/import',
      payload.csvFile,
      payload.auth_token
    );
    return response.json();
  }
);
