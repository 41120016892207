import { Card, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMeasureById } from '../../../store/slices/questionnaireSlice';
import CategoricalMeasure from './CategoricalMeasure';
import DropdownCategoricalMeasure from './DropdownCategoricalMeasure';
import NumericMeasure from './NumericMeasure';
import RangedNumericMeasure from './RangedNumericMeasure';
import TemporalMeasure from './TemporalMeasure';

export default function QuestionnaireMeasure({ measureId, addResponses }) {
  const [value, setValue] = useState(null);

  const measure = useSelector((state) => selectMeasureById(state, measureId));
  const changeValue = (val) => {
    setValue(val);
    addResponses(val);
  };

  let inputContent;

  if (measure) {
    if (measure.eligible_answers) {
      if (measure.eligible_answers.length > 5) {
        inputContent = (
          <DropdownCategoricalMeasure
            measure={measure}
            value={value}
            setValue={changeValue}
          />
        );
      } else {
        inputContent = (
          <CategoricalMeasure
            measure={measure}
            value={value}
            setValue={changeValue}
          />
        );
      }
    } else if (measure.range) {
      inputContent = (
        <RangedNumericMeasure
          measure={measure}
          value={value}
          setValue={changeValue}
        />
      );
    } else if (measure.date_range) {
      inputContent = (
        <TemporalMeasure
          measure={measure}
          value={value}
          setValue={changeValue}
        />
      );
    } else {
      inputContent = (
        <NumericMeasure
          measure={measure}
          value={value}
          setValue={changeValue}
        />
      );
    }
  }

  return (
    <Card
      sx={{
        backgroundColor: 'background.default',
        borderBottomWidth: '2px',
        borderColor: 'divider',
        p: 1
      }}>
      {measure && <Typography>{measure.prompt}</Typography>}
      <div className="flex justify-center">{inputContent}</div>
    </Card>
  );
}
