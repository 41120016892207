import { Copyright } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        backgroundColor: 'navbar',
        paddingTop: '0.7rem',
        paddingBottom: '0.7rem'
      }}>
      <div className="flex flex-col w-full items-center">
        <div className="flex w-full justify-center items-center">
          <Typography
            color="textSecondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mx: 1.8,
              fontSize: '0.775rem'
            }}>
            <Copyright fontSize="inherit" />
            {`${new Date().getFullYear()} Fujitsu`}
          </Typography>

          <Typography
            color="textSecondary"
            variant="subtitle1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mx: 1.8,
              fontSize: '0.775rem'
            }}>
            <Link
              href="/about"
              underline="none"
              color="inherit"
              sx={{ mr: 0.6 }}>
              About
            </Link>
            |
            <Link href="/faq" underline="none" color="inherit" sx={{ mx: 0.6 }}>
              FAQ
            </Link>
            |
            <Link
              href="mailto:John.Hetherington@fujitsu.com"
              underline="none"
              color="inherit"
              sx={{ mx: 0.6 }}>
              Contact
            </Link>
            |
            <Link
              href="/api/swagger.json"
              underline="none"
              color="inherit"
              sx={{ ml: 0.6 }}>
              API
            </Link>
          </Typography>

          <div className="mx-3 flex flex-col">
            <Link
              href="/terms-of-use"
              underline="none"
              color="textSecondary"
              variant="subtitle1"
              sx={{ fontSize: '0.675rem' }}>
              Terms of Use
            </Link>
            <Link
              href="/privacy-policy"
              underline="none"
              color="textSecondary"
              variant="subtitle1"
              sx={{ fontSize: '0.675rem' }}>
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </Box>
  );
}
