import { TableChartOutlined, ViewAgenda, ViewList } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserPreferences,
  setQuestionLayout
} from '../../store/slices/userSlice';
import {
  selectOutcomeById
} from '../../store/slices/outcomeSlice';

export default function QuestionLayoutToggle({ outcomeId }) {
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const dispatch = useDispatch();
  const outcome = useSelector((_state) => selectOutcomeById(_state, outcomeId));
  let canSeeRankedLayout = false
  if (outcome) {
    canSeeRankedLayout =
      (outcome.statuses.includes('Evaluation') ||
        outcome.statuses.includes('Forecasting')) &&
        (outcome.forecast_mechanism === 'manual')
  }
  
  useEffect(() => {
    if (!canSeeRankedLayout && localStorage.getItem('questionLayout') === 'ranked') {
      localStorage.setItem('questionLayout', 'card');
      dispatch(setQuestionLayout('card'));
    }
  }, [canSeeRankedLayout, dispatch]);


  const handleLayoutChange = (event, newLayout) => {
    if (newLayout !== null) { // enforces that at least one button must be active
      localStorage.setItem('questionLayout', newLayout);
      dispatch(setQuestionLayout(newLayout));
    }
  };

  return (
    <div>
      {outcome && <ToggleButtonGroup
        value={userPreferences.question_layout}
        exclusive
        onChange={handleLayoutChange}
        aria-label="Layout"
        sx={{}}
      >
        <ToggleButton color="primary" sx={{}} variant="outlined" value="card">
          <ViewAgenda />
          Card
        </ToggleButton>
        <ToggleButton color="primary" sx={{}} variant="outlined" value="detail">
          <ViewList />
          Detail
        </ToggleButton>
        {canSeeRankedLayout &&
          <ToggleButton color="primary" sx={{}} variant="outlined" value="ranked">
            <TableChartOutlined />
            Ranked
          </ToggleButton>}
      </ToggleButtonGroup>}
    </div>
  );
}
