import { useTheme } from '@emotion/react';
import React from 'react';

export default function LandingPageGeneration() {
  const theme = useTheme();
  return (
    <svg
      role="img"
      title="Icon of a crowd with two speech bubbles"
      id="Pictogram_artwork"
      data-name="Pictogram artwork"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63 63"
      style={{
        fill: theme.palette.mode === 'dark' ? 'white' : 'black',
        position: 'absolute',
        top: '21%',
        left: '21%',
        width: '55%',
        height: '55%'
      }}>
      <path d="m54.51,36.35c0-1.91-1.52-3.54-3.76-4.34.89-1.01,1.36-2.31,1.32-3.66.11-2.63-1.91-4.85-4.54-4.99-2.61.16-4.61,2.38-4.51,4.99-.1,2.59,1.85,4.79,4.43,4.99h.08c2.69,0,4.99,1.32,4.99,2.88v2.94c0,.55.45,1,1,1h0c.55,0,1-.45,1-1v-2.82Zm-9.47-7.99c-.11-1.51.99-2.83,2.49-3,1.51.15,2.63,1.48,2.52,3,.15,1.55-.98,2.93-2.52,3.09-1.53-.17-2.64-1.55-2.49-3.09h0Z" />
      <path d="m9.64,40.49h0c.55,0,1-.45,1-1v-2.95c0-1.56,2.28-2.87,4.99-2.87,2.63-.16,4.65-2.41,4.52-5.04.11-2.62-1.9-4.84-4.52-4.99-2.61.15-4.62,2.37-4.52,4.99-.01,1.31.46,2.59,1.34,3.57-2.25.81-3.8,2.43-3.8,4.35v2.95c0,.55.45,1,1,1h0Zm3.48-11.85c-.1-1.52,1.01-2.84,2.52-3,1.51.15,2.63,1.48,2.52,3,.13,1.54-1.01,2.9-2.54,3.05-1.52-.16-2.64-1.52-2.51-3.05h0Z" />
      <path d="m14.63,47.27v2c0,.55.45,1,1,1h0c.55,0,1-.45,1-1v-1.99c0-1.56,2.28-2.88,4.99-2.88s4.99,1.32,4.99,2.87v3.07c0,.55.45,1,1,1h0c.55,0,1-.45,1-1v-3.06c0-2.06-1.74-3.79-4.28-4.51,1.16-1,1.82-2.46,1.8-4,.11-2.62-1.9-4.84-4.51-4.99-2.61.15-4.62,2.37-4.52,4.99-.01,1.53.65,3,1.81,4-2.52.73-4.28,2.45-4.28,4.5h0Zm6.98-11.54c1.51.16,2.62,1.48,2.51,3,.1,1.51-1.01,2.83-2.51,3-1.51-.16-2.62-1.48-2.52-3-.1-1.52,1.01-2.84,2.52-3h0Z" />
      <path d="m46.05,38.77c.11-2.62-1.9-4.84-4.52-4.99-2.61.15-4.62,2.37-4.52,4.99-.01,1.53.65,3,1.81,4-2.51.72-4.25,2.44-4.25,4.49v3c0,.55.45,1,1,1h0c.55,0,1-.45,1-1v-3c0-1.56,2.27-2.88,4.99-2.88s4.99,1.32,4.99,2.87v2.1c0,.55.45,1,1,1h0c.55,0,1-.45,1-1v-2.06c0-2.06-1.74-3.79-4.28-4.51,1.15-1.01,1.8-2.47,1.78-4.01h0Zm-4.52-3c1.51.15,2.63,1.48,2.52,3,.1,1.52-1.01,2.84-2.52,3-1.51-.16-2.62-1.48-2.52-3-.13-1.54,1-2.89,2.53-3.04v.04Z" />
      <path d="m22.6,25.07h1.3v3.29c0,.3.18.58.46.7.09.04.19.05.29.05.2,0,.39-.08.53-.22l3.73-3.78h2.73c1.49-.02,2.69-1.25,2.67-2.75v-.25h1l3.73,3.77c.3.28.78.26,1.06-.04.12-.13.19-.3.2-.48v-3.28h1.26c1.5.01,2.72-1.2,2.73-2.7,0,0,0-.01,0-.02v-5.99c.02-1.5-1.18-2.73-2.68-2.75-.02,0-.03,0-.05,0h-8.97c-1.5-.01-2.72,1.2-2.73,2.7,0,.02,0,.03,0,.05v.17c0,.06-.05.11-.11.11h-7.13c-1.5-.01-2.72,1.2-2.73,2.7,0,0,0,.01,0,.02v5.99c0,1.5,1.22,2.71,2.72,2.71,0,0,0,0,.01,0h0Zm-1.24-8.7c-.02-.67.51-1.23,1.19-1.25.02,0,.03,0,.05,0h7.16c.89,0,1.61-.72,1.61-1.61v-.14c-.02-.67.51-1.23,1.19-1.25,0,0,.01,0,.02,0h8.97c.67-.01,1.23.53,1.24,1.2,0,.02,0,.03,0,.05v5.99c-.01.67-.56,1.21-1.24,1.21h-1.99c-.41,0-.74.34-.74.75v2.18l-2.66-2.7c-.14-.15-.34-.23-.54-.23h-1.99c-.41,0-.74.33-.74.74v1c.02.68-.52,1.24-1.19,1.26-.04,0-.08,0-.11,0h-3.04c-.2,0-.39.08-.53.23l-2.66,2.7v-2.14c0-.41-.33-.74-.74-.75h-2.04c-.67-.01-1.21-.57-1.2-1.24,0,0,0,0,0-.01v-5.99Z" />
    </svg>
  );
}
