import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserGroups,
  selectAllUserGroups
} from '../../store/slices/userGroupSlice';

import { Button, Divider, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { ClipLoader } from 'react-spinners';
import UserGroupCard from '../../components/cards/UserGroupCard';
import CreateUserGroupModal from '../../components/modals/CreateUserGroupModal';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';

export default function UserGroupsView() {
  const dispatch = useDispatch();
  const userGroupStatus = useSelector((state) => state.userGroups.status);
  const { isLoggedIn, userData } = useContext(AuthContext);
  let { groupId } = useParams();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [creatorDisabled, setCreatorDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (userGroupStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUserGroups(token));
    }
  }, [userGroupStatus, dispatch]);
  const userGroups = useSelector((state) => selectAllUserGroups(state));

  const theme = useTheme();
  const createUserGroup = () => {
    setShowModal(true);
    setCreatorDisabled(true);
  };

  const handleOnSearch = (string, results) => {
    // console.log(string);
  };
  const handleOnHover = (result) => {
    // console.log(result);
  };

  const handleOnSelect = (item) => {
    setSelectedGroup(item);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const handleOnClear = () => {
    // console.log("Cleared");
  };

  let groupCard;

  if (userGroupStatus === 'loading') {
    groupCard = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (userGroupStatus === 'succeeded') {
    if (selectedGroup || groupId) {
      groupCard = (
        <div className="flex w-full justify-center">
          {selectedGroup ? (
            <UserGroupCard groupId={selectedGroup.id} />
          ) : (
            <UserGroupCard groupId={groupId} />
          )}
        </div>
      );
    } else {
      groupCard = (
        <p className="text-sm m-10 text-center">
          Enter name to view user group information.
        </p>
      );
    }
  } else {
    groupCard = (
      <p className="text-sm m-10 text-center">
        Enter name to view user group information.
      </p>
    );
  }

  return (
    <div className="UserSettingsView in m-5">
      {showModal && isLoggedIn && userData.role === 'Admin' && (
        <CreateUserGroupModal
          shown={showModal}
          close={() => {
            setShowModal(false);
          }}
          setCreatorDisabled={(val) => {
            setCreatorDisabled(val);
          }}
        />
      )}
      <div className="flex ml-5 justify-between items-center my-2">
        <HierarchicalBreadcrumbs
          outcomes={true}
          userGroups={true}
        />
        {isLoggedIn && userData.role === 'Admin' && (
          <Button
            variant="contained"
            onClick={createUserGroup}
            disabled={creatorDisabled}
            sx={{ mx: 1 }}>
            Create User Group
          </Button>
        )}
      </div>

      <div className="mt-4 mx-8">
        <h3 className="text-md font-semibold ml-4 mb-1">Search for Groups</h3>
        <ReactSearchAutocomplete
          items={userGroups}
          fuseOptions={{ keys: ['name'] }}
          resultStringKeyName="name"
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          onClear={handleOnClear}
          styling={{
            zIndex: 4,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            iconColor: theme.palette.primary.main,
            hoverBackgroundColor: theme.palette.hover
          }}
          autoFocus
        />
      </div>
      <Divider sx={{ mt: 3, mb: 2 }} />

      {groupCard}
    </div>
  );
}
