import { TextField, FormControl, Autocomplete } from "@mui/material";
import { useState } from "react";

export default function MultiSelectDropdown({
  label,
  options,
  selectedValues,
  onSelectedValuesChange,
}) {
  const [value, setValue] = useState(selectedValues);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onSelectedValuesChange(newValue);
  };

  return (
    <div>
      <FormControl sx={{ my: 1.2 }} className={`w-full`}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={options}
          getOptionLabel={(option) => option}
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={label} />
          )}
        />
      </FormControl>
    </div>
  );
}
