import { UploadFile } from '@mui/icons-material';
import {
  Button,
  Card,
  Collapse,
  Divider,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadData } from '../../store/slices/adminSlice';
import InfoTooltip from './InfoTooltip';

export default function DataImporter() {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const renderFileList = () => (
    <>
      {[...files].map((f, i) => (
        <ListItem key={i}>
          <ListItemIcon>
            <UploadFile />
          </ListItemIcon>
          <ListItemText key={i} primary={f.name} />
        </ListItem>
      ))}
    </>
  );

  const removeFiles = () => {
    setFiles([]);
  };

  const handleImport = async () => {
    for (const file of files) {
      const token = localStorage.getItem('auth_token');
      try {
        await dispatch(uploadData({ csvFile: file, auth_token: token }))
          .unwrap()
          .then((response) => {
            setResponseMessage(`Successfully imported data`);
            removeFiles();
          });
      } catch (err) {
        setErrorMessage(`Error: ${err.message}`);
      }
    }
  };

  return (
    <div className="my-1 flex flex-col">
      <Divider />
      <Typography
        sx={{ mt: 0.5, ml: 2 }}
        color="text.secondary"
        display="block"
        variant="caption">
        Import Data
      </Typography>
      <div className="p-2 flex items-center">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="flex items-start justify-end mr-1">
            <FormLabel sx={{ fontSize: '0.8rem', color: 'text.primary' }}>
              Import Hivemind data via CSV file import.
            </FormLabel>
            <div className="ml-1 pb-1 text-sm">
              <InfoTooltip text="Import data from a CSV file." />
            </div>
          </div>
          <div className="flex-col items-center justify-start">
            <Button sx={{ mb: 1 }} variant="contained" component="label">
              Choose file
              <input
                type="file"
                accept=".csv"
                hidden
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
            </Button>
            <Collapse in={files.length > 0}>
              <Card variant="outlined" sx={{ my: 1 }}>
                <List dense>{renderFileList()}</List>
              </Card>

              <Button
                sx={{ mt: 1, mx: 1 }}
                variant="contained"
                onClick={handleImport}>
                Import
              </Button>
              <Button
                sx={{
                  mt: 1,
                  mx: 1,
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                variant="contained"
                onClick={removeFiles}>
                {' '}
                Cancel
              </Button>
            </Collapse>
            <Typography sx={{ my: 0.6 }} color="error">
              {errorMessage}
            </Typography>
            <Typography sx={{ my: 0.6 }} color="success.main">
              {responseMessage}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
