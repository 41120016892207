import { TextField } from '@mui/material';
import { useEffect } from 'react';

export default function NumericMeasure({ measure, value, setValue }) {
  useEffect(() => {
    setValue(0);
  }, []);

  const changevalue = (event) => setValue(event.target.value);

  return (
    <TextField
      value={value}
      onChange={(event) => changevalue(event)}
      inputProps={{ inputMode: 'numeric' }}
    />
  );
}
