import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { selectSettingByName } from "../../store/slices/settingsSlice";
import { fetchUserByName, selectUserByUsername } from "../../store/slices/userSlice";
import ProfileCard from "../../components/cards/ProfileCard";
import HierarchicalBreadcrumbs from "../../components/other/HierarchicalBreadcrumbs";

export default function Profile() {
    const dispatch = useDispatch();
    const usernameSettings = useSelector((state) => selectSettingByName(state, "View Usernames"));
    const { username } = useParams();
    const user = useSelector((state) => selectUserByUsername(state, username));

    useEffect(() => {
        if (username && user === undefined) {
            const token = localStorage.getItem('auth_token');
            dispatch(fetchUserByName({username: username, auth_token: token}));
        }
    }, [user, username, dispatch])

    return usernameSettings !== undefined ? (
        <div className="Profile m-5">
            <div className="flex justify-between ml-5 mt-7 items-center">
                <HierarchicalBreadcrumbs
                    outcomes={true}
                    profile={true}
                />
            </div>
            {user && 
                <ProfileCard userId={user.id} />
            }
        </div>
    ) : null;
}