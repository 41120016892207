import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';

import ProfileCard from '../../components/cards/ProfileCard';
import RegisterUserModal from '../../components/modals/RegisterUserModal';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, selectAllUsers } from '../../store/slices/userSlice';

import { Button, Divider, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';

export default function UsersView() {
  const { isLoggedIn, userData } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [registerDisabled, setRegisterDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const users = useSelector((state) => selectAllUsers(state));
  const usersStatus = useSelector((state) => state.users.status);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (usersStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUsers(token));
    }
  }, [usersStatus, dispatch]);


  const registerUser = () => {
    setShowModal(true);
    setRegisterDisabled(true);
  };

  const handleOnSelect = (item) => {
    setSelectedUser(item);
  };

  return (
    <div className="UserSettingsView m-5">
      {showModal && isLoggedIn && userData.role === 'Admin' && (
        <RegisterUserModal
          shown={showModal}
          close={() => {
            setShowModal(false);
          }}
          setRegisterDisabled={(val) => {
            setRegisterDisabled(val);
          }}
        />
      )}
      <div className="flex ml-5 justify-between items-center my-2">
        <HierarchicalBreadcrumbs outcomes={true} users={true} />
        <div className="flex">
          <Button
            variant="contained"
            onClick={() => {
              navigate('/user-groups');
            }}
            size="medium"
            sx={{ mx: 1 }}>
            User Groups
          </Button>
          {isLoggedIn && userData.role === 'Admin' && (
            <Button
              variant="contained"
              onClick={registerUser}
              disabled={registerDisabled}
              sx={{ mx: 1 }}>
              Register New User
            </Button>
          )}
        </div>
      </div>

      <div className="mt-4 mx-8">
        <h3 className="text-md font-semibold ml-4 mb-1">Search for Users</h3>
        <ReactSearchAutocomplete
          items={users}
          fuseOptions={{ keys: ['username'] }}
          resultStringKeyName="username"
          onSelect={handleOnSelect}
          styling={{
            zIndex: 4,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            iconColor: theme.palette.primary.main,
            hoverBackgroundColor: theme.palette.hover
          }}
          autoFocus
        />
      </div>
      <Divider sx={{ mt: 3, mb: 2 }} />

      {selectedUser ? (
        <div className="flex w-full justify-center">
          <ProfileCard userId={selectedUser.id} />
        </div>
      ) : (
        <p className="text-sm m-10 text-center">
          Enter username to view user information.
        </p>
      )}
    </div>
  );
}
