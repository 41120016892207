import React, { useContext } from 'react';
import { AuthContext } from '../../App';

import CreateOutcome from '../../components/other/CreateOutcome';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';

export default function SubmitView() {
  const { userData } = useContext(AuthContext); 
  return (
    userData.username? // forces the page to wait for userdata to be loaded before rendering, otherwise outcome caching breaks
    <div className="SubmitOutcomeView mt-7">
      <div className="flex justify-between ml-10 items-center">
        <HierarchicalBreadcrumbs outcomes={true} submit={true} />
      </div>
      <div className="flex justify-center w-full">
        <div className="w-full md:w-3/4 lg:w-2/3 2xl:w-1/2">
          <CreateOutcome />
        </div>
      </div>
    </div>:null
  );
}
