import { createAsyncThunk } from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

export const autoGenerateQuestions = createAsyncThunk(
  '/auto/question-generation',
  async (payload) => {
    const response = await hivemindAPI.post(
      `/api/auto/question-generation`,
      {
        outcome_id: payload.outcome_id,
        outcome_context: payload.outcome_context
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchRecommendedQuestions = createAsyncThunk(
  'auto/question-recommendation',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/auto/question-recommendation',
      { question_text: payload.question_text },
      payload.auth_token
    );
    return response.json();
  }
);
