import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectUserGroupById, updateUserGroup, addUsersToUserGroup, removeUsersFromUserGroup, addOutcomesToUserGroup, removeOutcomesFromUserGroup } from "../../store/slices/userGroupSlice";
import { selectAllUsers } from '../../store/slices/userSlice';
import { selectAllOutcomes, fetchOutcomes } from "../../store/slices/outcomeSlice";
import MultiSelectDropdown from "../other/MultiSelectDropdown"

import { Button, Card, TextField, Typography } from "@mui/material";
import { AuthContext, checkTokenStatus } from "../../App";

export default function ModifyUserGroupModal({
  shown,
  close,
  setCreatorDisabled,
  groupId
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const outcomeStatus = useSelector(
    (state) => state.outcomes.outcomes.status
  );

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({"auth_token": token}));
    }
  }, [outcomeStatus, dispatch]);

  const group = useSelector((state) => selectUserGroupById(state, groupId));
  const users = useSelector((state) => selectAllUsers(state));
  const userNames = users.map(user => {return user.username})
  const outcomes = useSelector((state) => selectAllOutcomes(state));
  const outcomeNames = outcomes.map(outcome => {return outcome.title})
  const [name, setName] = useState(group.name);
  const [description, setDescription] = useState(group.description);
  const [selectedUsers, setSelectedUsers] = useState(group.users? group.users.map(user => user.username) : []);
  const [selectedOutcomes, setSelectedOutcomes] = useState(group.outcomes? group.outcomes.map(outcome => outcome.title) : []);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modifyRequestStatus, setModifyRequestStatus] = useState("idle");
  const changeName = (event) => setName(event.target.value);
  const changeDescription = (event) => setDescription(event.target.value);
  
  const handleUsersChange = (selectedValues) => {
    setSelectedUsers(selectedValues);
  };

  const handleOutcomesChange = (selectedValues) => {
    setSelectedOutcomes(selectedValues);
  };

  const handleModify = async () => {
    if (checkTokenStatus() && modifyRequestStatus === "idle") {
      let isMounted = true;
      setErrorMessage('');
      try {
        setModifyRequestStatus("pending");
        setIsLoggedIn(true);
        const token = localStorage.getItem("auth_token");

        const addedUserIds = selectedUsers
        .filter(selectedUser => !group.users.some(groupUser => groupUser.username === selectedUser))
        .map(selectedUser => {
          const matchingUser = users.find(user => user.username === selectedUser);
          return matchingUser ? matchingUser.id : null;
        })
        .filter(id => id !== null);
    
        const removedUserIds = group.users
        .filter(groupUser => !selectedUsers.some(selectedUser => selectedUser === groupUser.username))
        .map(groupUser => {
          const matchingUser = users.find(user => user.username === groupUser.username);
          return matchingUser ? matchingUser.id : null;
        })
        .filter(id => id !== null);

        const addedOutcomeIds = selectedOutcomes
        .filter(selectedOutcome => !group.outcomes.some(groupOutcome => groupOutcome.title === selectedOutcome))
        .map(selectedOutcome => {
          const matchingOutcome = outcomes.find(outcome => outcome.title === selectedOutcome);
          return matchingOutcome ? matchingOutcome.id : null;
        })
        .filter(id => id !== null);
    
      const removedOutcomeIds = group.outcomes
        .filter(groupOutcome => !selectedOutcomes.some(selectedOutcome => selectedOutcome === groupOutcome.title))
        .map(groupOutcome => {
          const matchingOutcome = outcomes.find(outcome => outcome.title === groupOutcome.title);
          return matchingOutcome ? matchingOutcome.id : null;
        })
        .filter(id => id !== null);

        let payload = {
          id: groupId,
          name: name,
          description: description,
          auth_token: token,
          user_ids: [],
          outcome_ids: []
        };
        if (name !== group.name || description !== group.description) {
          dispatch(updateUserGroup(payload))
            .unwrap()
            .then((response) => {
              if (response.status === "success" && response.data.id) {
                setResponseMessage(`Group ${name} Successfully Updated`);
              }
          });
        }
        //Add new users
        if(addedUserIds.length > 0) {
          payload.user_ids = addedUserIds;
          dispatch(addUsersToUserGroup(payload))
            .unwrap()
            .then((response) => {
              if (response.status === "success") {
                setResponseMessage(`Group ${name} Successfully Updated`);
              }
            });
        }
        //Remove old users
        if(removedUserIds.length > 0) {
          payload.user_ids = removedUserIds;
          dispatch(removeUsersFromUserGroup(payload))
            .unwrap()
            .then((response) => {
              if (response.status === "success") {
                setResponseMessage(`Group ${name} Successfully Updated`);
              }
            });
        }
        //Add new outcomes
        if(addedOutcomeIds.length > 0) {
          payload.outcome_ids = addedOutcomeIds;
          dispatch(addOutcomesToUserGroup(payload))
            .unwrap()
            .then((response) => {
              if (response.status === "success") {
                setResponseMessage(`Group ${name} Successfully Updated`);
              }
            });
        }
        //Remove old users
        if(removedOutcomeIds.length > 0) {
          payload.outcome_ids = removedOutcomeIds;
          dispatch(removeOutcomesFromUserGroup(payload))
            .unwrap()
            .then((response) => {
              if (response.status === "success") {
                setResponseMessage(`Group ${name} Successfully Updated`);
              }
            });
        }
        setResponseMessage(`Group ${name} Successfully Updated`);
      } catch (err) {
        setErrorMessage(`Failed to update group: ${err.message}`);
      } finally {
        if (isMounted) {
          setModifyRequestStatus("idle");
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem("auth_token");
      setIsLoggedIn(false);
      return <Navigate to={"/login"} />;
    } else if (name === "") {
      setErrorMessage("Please set a new name before update a new group");
    } else {
      setErrorMessage("Group could not be updated.");
    }
  };

  const closeModal = () => {
    close();
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 z-10"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <Typography
            sx={{ mb: 0.6, fontWeight: "bold", fontSize: "1.1rem" }}
            className="text-center"
          >
            Update Group
          </Typography>
          {!responseMessage && (
            <div>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}

              <div className="mx-2">
                <Typography className="">Group Name</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  autoComplete="Name"
                  value={name}
                  onChange={(event) => changeName(event)}
                  placeholder="Enter Name"
                  sx={{ my: 1.2 }}
                  className={`w-full`}
                />
              </div>

              <div className="mx-2">
                <Typography className="">Group Description</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  autoComplete="Description"
                  value={description}
                  onChange={(event) => changeDescription(event)}
                  placeholder="Enter Description"
                  sx={{ my: 1.2 }}
                  className={`w-full`}
                />
              </div>

              <div className="mx-2">
                <Typography className="">Users</Typography>
                <MultiSelectDropdown
                  label={"Select user(s)"}
                  options={userNames}
                  selectedValues={selectedUsers}
                  onSelectedValuesChange={handleUsersChange}
                />
              </div>

              <div className="mx-2">
                <Typography className="">Outcomes</Typography>
                <MultiSelectDropdown
                  label={"Select outcome(s)"}
                  options={outcomeNames}
                  selectedValues={selectedOutcomes}
                  onSelectedValuesChange={handleOutcomesChange}   
                />
              </div>

              <div className="flex mt-4">
                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "gray",
                      ":hover": { backgroundColor: "#757575" },
                    }}
                    onClick={closeModal}
                    className="w-full"
                  >
                    Cancel
                  </Button>
                </div>

                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    onClick={handleModify}
                    className="w-full"
                  >
                    Update Group
                  </Button>
                </div>
              </div>
            </div>
          )}

          {responseMessage && (
            <div>
              {responseMessage && (
                <Typography color="success.main" className="text-center">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  onClick={closeModal}
                  variant="contained"
                  className="w-6/12"
                >
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}