import { MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';

export default function DropdownCategoricalMeasure({
  measure,
  value,
  setValue
}) {
  useEffect(() => {
    setValue(measure.eligible_answers[0]);
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let menuItems;
  menuItems = measure.eligible_answers.map((category, index) => (
    <MenuItem key={index} value={category.answer}>
      {category.answer}
    </MenuItem>
  ));

  return (
    <Select
      value={value}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '30%',
        maxHeight: '14rem'
      }}
      MenuProps={{ PaperProps: { sx: { maxHeight: '24rem', width: '10rem' } } }}
      onChange={handleChange}>
      {menuItems}
    </Select>
  );
}
