import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext } from '../../App';
import CommentCard from '../../components/cards/CommentCard';
import QuestionCard from '../../components/cards/QuestionCard';
import RejectionCommentCard from '../../components/cards/RejectionCommentCard';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchQuestionById,
  selectCommentById,
  selectQuestionById
} from '../../store/slices/outcomeSlice';

export default function CommentView() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useContext(AuthContext);
  const [isVotingDisabled, setIsVotingDisabled] = useState(false);
  let { questionId, commentId } = useParams();
  const navigate = useNavigate();
  const [fetchQuestionStatus, setFetchQuestionStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const comment = useSelector((state) => selectCommentById(state, commentId));
  const question = useSelector((state) => selectQuestionById(state, questionId));

  const openParentComment = () => {
    if (comment.parentId) {
      navigate(`/questions/${questionId}/comment/${comment.parentId}`);
    }
  };
  const openQuestionView = () => {
    navigate(`/questions/${questionId}`);
  };

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    function fetchQuestionData() {
      if (isMounted) {
        if (fetchQuestionStatus === 'idle') {
          try {
            setFetchQuestionStatus('pending');
            const token = localStorage.getItem('auth_token');
            dispatch(
              fetchQuestionById({ questionId: questionId, auth_token: token })
            )
              .unwrap()
              .then((response) => {
                if (response.data.id === parseInt(questionId)) {
                  setFetchQuestionStatus('succeeded');
                } else {
                  setFetchQuestionStatus('failed');
                  setErrorMessage(`Failed to fetch question data`);
                }
              });
          } catch (err) {
            setErrorMessage(`Failed to fetch question data: ${err.message}`);
          }
        }
      }
    }
    fetchQuestionData();
    return () => {
      isMounted = false;
    };
  }, [fetchQuestionStatus, dispatch, questionId]);

  let content;
  if (fetchQuestionStatus === 'pending') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (fetchQuestionStatus === 'succeeded') {
    content = (
      <div>
        <QuestionCard
          question={question}
          isLoggedIn={isLoggedIn}
          isVotingDisabled={isVotingDisabled}
          setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
        />
        <div className="flex flex-col items-start">
          <Button
            variant="text"
            size="small"
            sx={{ ml: 6, pl: 1.2 }}
            onClick={openQuestionView}>
            View all comments
          </Button>
          {comment.parentId && (
            <Button
              variant="text"
              size="small"
              sx={{ ml: 6, pl: 1.2 }}
              onClick={openParentComment}>
              View parent comment
            </Button>
          )}
        </div>
        <div className="flex relative my-3 items-center mx-10">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400">Comments</span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        {comment.comment_type && comment.comment_type.includes('rejection') ? (
          <RejectionCommentCard
            questionId={parseInt(questionId)}
            commentId={parseInt(commentId)}
            maxIndentLevels={4}
            indentLevel={1}
          />
        ) : (
          <CommentCard
            questionId={parseInt(questionId)}
            commentId={parseInt(commentId)}
            maxIndentLevels={4}
            indentLevel={1}
          />
        )}
      </div>
    );
  } else {
    content = <p className="text-sm text-center">Question could be found.</p>;
  }

  return (
    <div className="CommentView mt-7">
      <div className="flex ml-10 items-center">
        <HierarchicalBreadcrumbs
          outcomes={true}
          question={true}
          questionId={questionId}
          comment={true}
          commentId={commentId}
        />
      </div>
      {errorMessage && (
        <p className="text-xs m-2 text-red-600 py-2">{errorMessage}</p>
      )}
      <section className="comment-view">{content}</section>
    </div>
  );
}
