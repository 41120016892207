import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  openRegister,
  register
} from '../store/slices/userSlice';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { ClipLoader } from 'react-spinners';
import SuccessModal from '../components/modals/SuccessModal';

export default function RegisterView({ verify }) {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    'Your registration was successful.'
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [registerRequestStatus, setRegisterRequestStatus] = useState('idle');
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const changeUsername = (event) => setUsername(event.target.value);
  const changeEmail = (event) => setEmail(event.target.value);
  const changePassword = (event) => setPassword(event.target.value);
  const changeConfirmPassword = (event) =>
    setConfirmPassword(event.target.value);
  const [disabled, setDisabled] = useState(false)
  const [sentFirstEmail, setSentFirstEmail] = useState(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const openLoginView = () => {
    navigate(`/login`);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    const canRegister =
      [username, email, password, confirmPassword].every(Boolean) &&
      registerRequestStatus === 'idle' &&
      password === confirmPassword &&
      validateEmail(email);
    if (canRegister) {
      try {
        setIsMounted(true);
        setRegisterRequestStatus('pending');
        let payload = {
          username: username,
          password: password,
          email: email
        };
        if (!verify) {
          await dispatch(openRegister(payload))
            .unwrap()
            .then((response) => {
              if (response.status === 'success') {
                setSuccess(true);
              } else {
                throw new Error('Failed to register.');
              }
            });
        } else {
          await dispatch(register(payload))
            .unwrap()
            .then((response) => {
              if (response.status === 'success') {
                setSentFirstEmail(true)
                setDisabled(true);
                setTimeout(() => {
                  setDisabled(false);
                }, 60000)
              } else {
                throw new Error('Failed to register.');
              }
            });
        }
      } catch (err) {
        if (err.name && err.name === 'SyntaxError') {
          setErrorMessage('Failed to register. Possible server error.');
        } else {
          setErrorMessage(`Failed to register: ${err.message}`);
        }
      } finally {
        setRegisterRequestStatus('idle');
        setIsMounted(false);
      }
    } else if (!username || !email || !password || !confirmPassword) {
      setErrorMessage(
        'Please ensure you enter a username and password before trying to register.'
      );
    } else if (password !== confirmPassword) {
      setErrorMessage('Please ensure both passwords match.');
    } else if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email');
    } else {
      setErrorMessage('Failed to register.');
    }
  };

  return (
    <div className="flex justify-center">
      <Card
        sx={{ p: 6, mx: 6, my: 12 }}
        className="lg:w-2/5 md:w-1/2 sm:w-3/5"
        raised>
        <form className="RegisterView">
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
            className="text-center">
            Register
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }} className="text-center">
            If you are an Outcome Owner, please{' '}
            <Link href="mailto:John.Hetherington@fujitsu.com">contact us</Link>.
          </Typography>
          <Typography color="error">{errorMessage}</Typography>
          <div className="w-full mt-2">
            <TextField
              id="outlined-basic"
              label="Username"
              autoComplete="false"
              variant="outlined"
              fullWidth
              sx={{
                '& fieldset': {
                  borderRadius: '9999px'
                }
              }}
              type="text"
              value={username}
              onChange={(event) => changeUsername(event)}
            />
          </div>
          <div className="w-full mt-2">
            <TextField
              id="outlined-basic1"
              label="Email"
              variant="outlined"
              fullWidth
              sx={{
                '& fieldset': {
                  borderRadius: '9999px'
                }
              }}
              type="email"
              value={email}
              onChange={(event) => changeEmail(event)}
            />
          </div>
          <div className="mt-2">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                autoComplete="false"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                fullWidth
                sx={{
                  '& fieldset': {
                    borderRadius: '9999px'
                  }
                }}
                value={password}
                onChange={(event) => changePassword(event)}
              />
            </FormControl>
          </div>

          <div className="mt-2 mb-6">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password1">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password1"
                autoComplete="false"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                fullWidth
                sx={{
                  '& fieldset': {
                    borderRadius: '9999px'
                  }
                }}
                value={confirmPassword}
                onChange={(event) => changeConfirmPassword(event)}
              />
            </FormControl>
          </div>
          {sentFirstEmail && <Typography
            sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
            className="text-center">
            A registration email has been sent to the email address you entered, please check your inbox and junk folder.
          </Typography>}
          <div className="flex w-full mt-5 mb-5">
            <Button
              type="submit"
              variant="contained"
              onClick={handleRegister}
              disabled={isMounted || disabled}
              sx={{
                borderRadius: '9999px'
              }}
              className="w-full">
              {isMounted ? (
                <ClipLoader color="#ffffff" loading={true} size={50} />
              ) : disabled ? 'Please wait 60s before resending email'
                : sentFirstEmail ? 'Resend email' : 'Register'}
            </Button>
          </div>
          <div
            className="flex"
            style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div className="flex">
              <Typography sx={{ fontSize: '0.875rem' }}>
                Already have an account?{' '}
              </Typography>
              <Link
                href="/login"
                className="underline hover:cursor-pointer"
                color="primary.main"
                sx={{ mx: 0.6, fontSize: '0.875rem' }}>
                Login now
              </Link>
            </div>
            <div className="flex">
              <Typography sx={{ fontSize: '0.875rem' }}>
                Forgot your password?{' '}
              </Typography>
              <Link
                href="/reset-password"
                className="underline hover:cursor-pointer"
                color="primary.main"
                sx={{ mx: 0.6, fontSize: '0.875rem' }}>
                Reset password
              </Link>
            </div>
          </div>
        </form>
      </Card>
      {success && (
        <div>
          <SuccessModal
            shown={success}
            close={openLoginView}
            successMessage={successMessage}
          />
        </div>
      )}
    </div>
  );
}
