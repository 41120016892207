import { Button, Card } from '@mui/material';
import React, { useState } from 'react';
import { FiTriangle } from 'react-icons/fi';

export default function Dropdown({
  options,
  selectedOption,
  setSelectedOption,
  chevrons,
  selectedChevron,
  upChevron,
  downChevron,
  prefixText
}) {
  const [showOptions, setShowOptions] = useState(false);

  const handleSelectedOption = (option, closeDropdown) => {
    setSelectedOption(option);
    if (closeDropdown) {
      setShowOptions(false);
    }
  };

  const chevronColour = (direction, option) => {
    if (direction === 'up') {
      return selectedChevron === 'up' && option === selectedOption
        ? '#f87171'
        : '#d1d5db';
    } else if (direction === 'down') {
      return selectedChevron === 'down' && option === selectedOption
        ? '#f87171'
        : '#d1d5db';
    }
  };

  const upChevronClicked = (event, option) => {
    event.stopPropagation();
    upChevron();
    if (option !== selectedOption) {
      handleSelectedOption(option);
    }
  };

  const downChevronClicked = (event, option) => {
    event.stopPropagation();
    downChevron();
    if (option !== selectedOption) {
      handleSelectedOption(option, false);
    }
  };

  return (
    <div className="w-fit">
      <Button
        variant="outlined"
        onClick={() => setShowOptions(!showOptions)}
        sx={{
          borderWidth: '0.001rem'
        }}
        className="bg-white hover:bg-gray-100 border-2 border-gray-200 rounded max-w-40 text-sm px-2 py-2.5 text-center flex items-center justify-between max-h-9">
        <span>{prefixText ? prefixText + selectedOption : selectedOption}</span>
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            className="justify-self-end"
            strokeLinecap="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"></path>
        </svg>
      </Button>

      <Card
        id="dropdown"
        className={
          showOptions
            ? 'absolute z-10 max-w-44 rounded shadow'
            : 'hidden z-10 rounded shadow'
        }>
        <ul className="text-sm">
          {options.map((option) => {
            return (
              <li key={option}>
                <Card
                  onClick={(e) => handleSelectedOption(option, true)}
                  sx={{
                    '&:hover': {
                      bgcolor: 'hover'
                    }
                  }}
                  className="py-2 px-4 w-full text-left cursor-pointer flex justify-between items-center">
                  <span>{option}</span>
                  {chevrons && (
                    <div className="ml-8 flex flex-col">
                      <button onClick={(e) => upChevronClicked(e, option)}>
                        <FiTriangle
                          fill={chevronColour('up', option)}
                          stroke={chevronColour('up', option)}
                        />
                      </button>
                      <button onClick={(e) => downChevronClicked(e, option)}>
                        <FiTriangle
                          fill={chevronColour('down', option)}
                          stroke={chevronColour('down', option)}
                          style={{ transform: 'rotate(180deg)' }}
                        />
                      </button>
                    </div>
                  )}
                </Card>
              </li>
            );
          })}
        </ul>
      </Card>
    </div>
  );
}
