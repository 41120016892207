import { Card, Link, Typography } from '@mui/material';
import React from 'react';

export default function AboutView() {
  return (
    <Card className="my-16 lg:mx-30 md:mx-20 sm:mx-10 mx-10 p-8">
      <Typography
        sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}
        className="text-xl font-extrabold text-center">
        About Hivemind
      </Typography>
      <div className="mt-4">
        <Typography>
          The Hivemind project aims to harness crowd and artificial intelligence
          to support decision-makers in business and government.
        </Typography>
        <Typography>
          Hivemind will provide a structured platform that leverages crowd
          knowledge and machine learning to help leaders ask the right questions
          about future oppertunities and threats, improving their
          decision-making and reducing risk. This platform has been developed to
          trial proposed approaches to completing this aim.
        </Typography>
      </div>

      <div className="mt-4">
        <Typography>
          The Hivemind project is a collaboration between Fujitsu, the MIT's
          Centre for Collective Intelligence, and Aleph Insights. For further
          information contact John Hetherington, Hivemind Lead, Fujitsu,{' '}
          <Link href="mailto:John.Hetherington@fujitsu.com">
            John.Hetherington@fujitsu.com
          </Link>
          .
        </Typography>
      </div>
    </Card>
  );
}
