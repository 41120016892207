import moment from 'moment';
import React from 'react';
import { Typography } from '@mui/material';
import { selectOutcomeById } from '../../store/slices/outcomeSlice';
import { useSelector } from 'react-redux';
import {
  CalendarMonthOutlined,
  EventOutlined,
  MailOutline,
  QuestionMarkOutlined
} from '@mui/icons-material';

export default function OutcomeStatusText({ status, outcome_id, micro }) {
  const outcome = useSelector((state) =>
    selectOutcomeById(state, outcome_id)
  );

  const outcomeStatusText = () => {
    if (outcome) {
      var text = ''
      if (status === 'Generation') {
        text = 'Question generation';
      } else if (status === 'Evaluation') {
        text = 'Evaluation';
      } else if (status === 'Forecasting') {
        text = 'Forecasting';
      } else {
        return 'Closed';
      }
      if (micro) {
        return text + ' started'
      } else {
        return text
      }
    } else {
      return ''
    }
  };

  const timeBetweenStatusChange = () => {
    if (outcome) {
      if (status === 'Closed') {
        return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
      } else if (status === 'Generation') {
        return moment(outcome.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
      } else if (status === 'Evaluation') {
        return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
      } else {
        return moment(outcome.forecasting_start_date, 'YYYY-MM-DD').fromNow();
      }
    } else {
      return '';
    }
  };

  const timeTillFinish = () => {
    if (outcome) {
      if (status === 'Generation') {
        return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
      } else if (status === 'Evaluation') {
        return moment(outcome.forecasting_start_date, 'YYYY-MM-DD').fromNow();
      } else if (status === 'Forecasting') {
        return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return (
    micro ?
      <Typography
        sx={{
          mr: 2,
          fontSize: '0.85rem',
          p: 0,
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.secondary">
        <CalendarMonthOutlined
          fontSize="small"
          sx={{ mr: 0.3, mb: 0.3 }}
        />
        {outcomeStatusText()} {timeBetweenStatusChange()}. <br /> Ending in {timeTillFinish()}.
      </Typography>
      :
      <Typography
        sx={{ mx: 0.6, fontSize: '0.95rem' }}
        color="text.secondary">
        {outcomeStatusText()} ends in {timeTillFinish()}
      </Typography>
  )
}