import React from 'react';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';


export default function HelpLink({ section, position, tooltip }) {

  const handleOnClick = () => {
    const url = `/faq?section=${section}#${position}`;
    window.open(url, '_blank');
  };


  return (
    tooltip ?
      <Tooltip title={tooltip} >
        <IconButton
          aria-label="help"
          color="primary"
          onClick={handleOnClick}
        >
          <HelpOutlineIcon style={{ fontSize: 'medium' }} />
        </IconButton>
      </Tooltip>
      :
      <IconButton
        aria-label="help"
        color="primary"
        onClick={handleOnClick}
      >
        <HelpOutlineIcon style={{ fontSize: 'medium' }} />
      </IconButton>
  );
}
