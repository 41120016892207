import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

const filter = createFilterOptions();

export default function AutocompleteSource({ sourceValues, value, setValue }) {
  const [sourceFocused, setSourceFocused] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(newValue.inputValue);
        } else {
          setValue(newValue);
        }
      }}
      onBlur={() => {
        if (value !== '') {
          setValue(value);
        } else {
          setValue(localValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          // This creates a console error but gives us correct functionality. Worth revisiting.
          setLocalValue(params.inputValue);
          filtered.push(`${inputValue}`);
        }
        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      id="source-dropdown"
      options={sourceValues}
      getOptionLabel={(option) => {
        return option;
      }}
      renderOption={(props, option) => <li {...props}>{option}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Source *"
          onFocus={() => setSourceFocused(true)}
          onBlur={() => setSourceFocused(false)}
          helperText={sourceFocused && 'Trusted authority source i.e. the person you\'ll believe about this if they report on it'}
        />
      )}
    />
  );
}
