import {
  Button,
  Card,
  Checkbox,
  Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  selectQuestionsByOutcome,
  getOutcomeSubmissionLimit,
  submitForecastOverride
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';

export default function ForecastSubmissionModal({ close, outcomeId }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const submissionLimit = useSelector((state) =>
    getOutcomeSubmissionLimit(state)
  );
  // rejected questions should not appear in the modal
  const questions = useSelector((state) =>
    selectQuestionsByOutcome(state, outcomeId).filter(question => question.status !== 'Rejected')
  );

  const [responseMessage, setResponseMessage] = useState('');
  const [requestStatus, setRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedQuestionList, setSelectedQuestionList] = useState([])

  const handleChange = (id) => {
    if (errorMessage) setErrorMessage('')
    if (selectedQuestionList.includes(id)) {
      // if id already exists in selected list, remove it
      setSelectedQuestionList(selectedQuestionList.filter(questionID => questionID !== id))
    } else {

      if (selectedQuestionList.length === submissionLimit) {
        // can't add to the list if submission limit is reached
        setErrorMessage(`Cannot select more than ${submissionLimit} questions.`)
      } else {
        // if id does not exist in selected list, add it
        setSelectedQuestionList([...selectedQuestionList, id])
      }
    }
  }

  const canSubmit =
    [outcomeId, checkTokenStatus()].every(Boolean) &&
    selectedQuestionList.length <= submissionLimit &&
    requestStatus === 'idle';

  const overrideForecasts = async () => {
    setErrorMessage('')
    if (canSubmit) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcome_id: outcomeId,
          forecast_override_list: selectedQuestionList,
          auth_token: token
        };
        await dispatch(submitForecastOverride(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setSelectedQuestionList([]);
            }
            if (response.status === 'success') {
              setResponseMessage(`Forecast choices successfully submitted`);
            }
          });
      } catch (err) {
        setErrorMessage(
          `Failed to override forecast submission: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (selectedQuestionList.length >= submissionLimit) {
      setErrorMessage(`Too many questions are selected, limit is ${submissionLimit}.`)
    }
    else {
      setErrorMessage('Failed to override forecast submission.');
    }
  };

  return (
    <div
      className="modal-backdrop"
      onClick={close}>
      <Card
        className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
        style={{
          maxHeight: "90vh",
          overflowY: 'auto'
        }}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.1rem' }}
            className="text-center">
            Submit Forecast Override
          </Typography>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          {!responseMessage && (
            <div>
              <div className="flex justify-start my-2">
                {/* <div className="break-words text-xs font-medium m-1 my-2"> */}
                <p>
                  Outcome owners can overrule the automated question selection system for submission to forecasting.
                  This allows you to give priority to particular questions you especially want submitted and scored
                  in the next stage.
                </p>
                {/* </div> */}
              </div>

              <div className="break-words text-black font-medium mb-3 w-full">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Selected {selectedQuestionList.length} of a maximum possible {submissionLimit}
                </Typography>
                <div
                  style={{
                    maxHeight: "60vh",
                    overflowY: "auto"
                  }}>
                  {questions && questions.map((question, index) => (
                    <div style={{ display: 'flex', marginBottom: '10px' }} key={index}>
                      <SmallQuestionCard questionId={question.id} />
                      <Checkbox
                        checked={selectedQuestionList.includes(question.id)}
                        onChange={() => handleChange(question.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end mt-2">
                <div className="mx-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={close}>
                    Cancel
                  </Button>
                </div>
                <div className="mx-2">
                  <Button variant="contained" onClick={overrideForecasts}>
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}
          {responseMessage && (
            <div className="my-2">
              {responseMessage && (
                <Typography color="success.main" className="text-center">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  onClick={close}
                  variant="contained"
                  className="w-4/12">
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
