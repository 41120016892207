import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  verify,
} from '../store/slices/userSlice';

import { Code } from '@mui/icons-material';
import {
  Button,
  Card,
  Link,
  TextField,
  Typography
} from '@mui/material';

export default function VerifyView() {
  const dispatch = useDispatch();
  const { token } = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [verifyRequestStatus, setVerifyRequestStatus] = useState('idle');
  const navigate = useNavigate();

  const changeCode = (event) => setCode(event.target.value);

  useEffect(() => {
    if (token !== "0") {
      setCode(token);
    }
  }, [token]);

  const canVerify =
    [code].every(Boolean) && verifyRequestStatus === "idle"
  const handleVerify = async (e) => {
    e.preventDefault();
    if (canVerify) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setVerifyRequestStatus('pending');
        await dispatch(verify(code))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              if (isMounted) {
                setVerifyRequestStatus('idle');
                isMounted = false;
                navigate('/login')
              }
            } else {
              throw new Error('Failed to verify.');
            }
          });
      } catch (err) {
        if (err.name && err.name === 'SyntaxError') {
          setErrorMessage('Failed to verify. Possible server error.');
        } else {
          setErrorMessage(`Failed to verify: ${err.message}`);
        }
        if (isMounted) {
          setVerifyRequestStatus('idle');
        }
        isMounted = false;
      }
    } else if (!Code) {
      setErrorMessage(
        'Please ensure you enter the code before trying to verify.'
      );
    } else {
      setErrorMessage('Failed to verify.');
    }
  };

  return (
    <div className="flex justify-center">
      <Card
        sx={{ p: 6, mx: 6, my: 12 }}
        className="lg:w-2/5 md:w-1/2 sm:w-3/5"
        raised>
        <form className="VerifyView">
          {/* <Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}} className="text-center">Log In</Typography> */}
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
            className="text-center">
            Verify
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }} className="text-center">
            Enter the verification code that has been emailed to you
          </Typography>
          <Typography color="error">{errorMessage}</Typography>
          <div className="w-full mt-2">
            <TextField
              id="outlined-basic"
              label="Verification Code"
              variant="outlined"
              fullWidth
              sx={{
                '& fieldset': {
                  borderRadius: '9999px'
                }
              }}
              type="text"
              value={code}
              onChange={(event) => changeCode(event)}
            />
          </div>

          <div className="flex w-full mt-5 mb-5">
            <Button
              onClick={(event) => handleVerify(event)}
              type="submit"
              variant="contained"
              sx={{
                borderRadius: '9999px'
              }}
              className="w-full">
              Verify
            </Button>
          </div>
          <div
            className="flex"
            style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div className="flex">
              <Typography sx={{ fontSize: '0.875rem' }}>
                Already have an account?{' '}
              </Typography>
              <Link
                href="/login"
                className="underline hover:cursor-pointer"
                color="primary.main"
                sx={{ mx: 0.6, fontSize: '0.875rem' }}>
                Login now
              </Link>
            </div>
            <div className="flex">
              <Typography sx={{ fontSize: '0.875rem' }}>
                Forgot your password?{' '}
              </Typography>
              <Link
                href="/reset-password"
                className="underline hover:cursor-pointer"
                color="primary.main"
                sx={{ mx: 0.6, fontSize: '0.875rem' }}>
                Reset password
              </Link>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}
