import { FormHelperText, Typography, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import useWindowDimensions from '../useWindowDimensions.jsx';

export default function OutcomeCreationDatesPicker({
  initialGenerationEndDate,
  setGenerationEndDate,
  initialForecastingStartDate,
  setForecastingStartDate,
  initialOutcomeEndDate,
  setOutcomeEndDate,
  continueForecasting,
  validSubmission,
  setValidSubmission
}) {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  var today = new Date();
  today.setHours(0, 0, 0, 0);
  const [generationDateRange, setGenerationDateRange] = useState([
    today,
    new Date(initialGenerationEndDate)
  ]);
  const [forecastingDateRange, setForecastingDateRange] = useState([
    new Date(initialForecastingStartDate),
    new Date(initialOutcomeEndDate)
  ]);

  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: width > 800 ? false : true,
        position: 'bottom'
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: function () {
            return '';
          },
          title: function (context) {
            return context[0].dataset.label;
          }
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex !== 15000;
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: 4,
        display: false
      },
      x: {
        type: 'time',
        time: {
          unit: 'day'
        },
        title: {
          display: false
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        },
        ticks: {
          color: theme.palette.text.primary,
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: 10
        }
      }
    }
  };

  var data = {
    datasets: [
      {
        label: 'Generation',
        data: [
          { x: generationDateRange[0], y: 3 },
          { x: generationDateRange[1] ? generationDateRange[1] : null, y: 3 }
        ],
        borderColor: theme.palette.statuses.mid1,
        backgroundColor: theme.palette.statuses.mid1,
        pointStyle: false
      },
      {
        label: 'Forecasting',
        data: [
          { x: forecastingDateRange[0] ? forecastingDateRange[0] : null, y: 2 },
          { x: forecastingDateRange[1] ? forecastingDateRange[1] : null, y: 2 }
        ],
        borderColor: theme.palette.statuses.mid3,
        backgroundColor: theme.palette.statuses.mid3,
        pointStyle: false
      },
      continueForecasting && {
        label: '(continued) Forecasting',
        data: [
          { x: forecastingDateRange[1] ? forecastingDateRange[1] : null, y: 2 },
          {
            x: forecastingDateRange[1]
              ? moment(forecastingDateRange[1]).add('2', 'weeks')
              : null,
            y: 2
          }
        ],
        borderColor: theme.palette.statuses.mid3,
        backgroundColor: theme.palette.statuses.mid3,
        pointStyle: false,
        borderDash: [5, 5]
      },
      {
        label: 'Closed',
        data: [
          { x: forecastingDateRange[1] ? forecastingDateRange[1] : null, y: 1 },
          {
            x: forecastingDateRange[1]
              ? moment(forecastingDateRange[1]).add('2', 'weeks')
              : null,
            y: 1
          }
        ],
        borderColor: theme.palette.statuses.mid4,
        backgroundColor: theme.palette.statuses.mid4,
        pointStyle: false
        // borderDash: [5, 5],
      }
    ]
  };
  var warning = '';
  if (
    generationDateRange[0] &&
    generationDateRange[1] &&
    forecastingDateRange[0] &&
    forecastingDateRange[1]
  ) {
    if (generationDateRange[1] < forecastingDateRange[0]) {
      warning =
        'There must not be a gap between Generation phase and Forecasting phase.';
    }
    if (generationDateRange[1] > forecastingDateRange[1]) {
      warning =
        'Generation phase cannot continue past outcome closure (end of forecasting).';
    }
    const genPhaseLenDays = Math.floor(
      Math.abs(generationDateRange[0] - generationDateRange[1]) /
        (1000 * 60 * 60 * 24)
    );
    if (genPhaseLenDays < 1) {
      warning = 'Generation phase cannot start and end on the same day.';
    }
    const forcastPhaseLenDays = Math.floor(
      Math.abs(forecastingDateRange[0] - forecastingDateRange[1]) /
        (1000 * 60 * 60 * 24)
    );
    if (forcastPhaseLenDays < 1) {
      warning = 'Forecasting phase cannot start and end on the same day.';
    }
  }
  useEffect(() => {
    if (
      generationDateRange[0] &&
      generationDateRange[1] &&
      forecastingDateRange[0] &&
      forecastingDateRange[1] &&
      !warning
    ) {
      setValidSubmission(true);
      setGenerationEndDate(generationDateRange[1]);
      setForecastingStartDate(forecastingDateRange[0]);
      setOutcomeEndDate(forecastingDateRange[1]);
    } else {
      if (validSubmission) {
        setValidSubmission(false);
      }
    }
  }, [
    forecastingDateRange,
    generationDateRange,
    setOutcomeEndDate,
    setForecastingStartDate,
    setGenerationEndDate,
    setValidSubmission,
    validSubmission,
    warning
  ]);

  return (
    <div className="">
      <div className="flex flex-wrap flex-row justify-between">
        <div className="flex flex-col w-2/5">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ukLocale}>
            <DatePicker
              id="date-picker"
              dateFormat="dd/MM/yyyy"
              selected={generationDateRange[1]}
              onChange={(newValue) => {
                setGenerationDateRange([today, newValue]);
              }}
              startDate={today}
              endDate={generationDateRange[1]}
              selectsEnd
              minDate={today}
              showYearDropdown
              placeholderText="Select date range *"
              customInput={
                <div>
                  {generationDateRange[1] === null
                    ? 'Select generation phase dates'
                    : generationDateRange[0].toLocaleDateString(
                        'en-GB',
                        dateOptions
                      ) +
                      ' - ' +
                      generationDateRange[1].toLocaleDateString(
                        'en-GB',
                        dateOptions
                      )}
                </div>
              }
            />
            <FormHelperText>Generation phase dates</FormHelperText>
          </LocalizationProvider>
        </div>
        <div className="flex flex-col w-2/5">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ukLocale}>
            <DatePicker
              id="date-picker"
              dateFormat="dd/MM/yyyy"
              selected={forecastingDateRange[0]}
              onChange={(newValue) => {
                setForecastingDateRange(newValue);
              }}
              startDate={forecastingDateRange[0]}
              endDate={forecastingDateRange[1]}
              selectsRange
              placeholderText="Select date range *"
              minDate={today}
              showYearDropdown
              customInput={
                <div>
                  {forecastingDateRange[0] === null
                    ? 'Select forecasting phase dates'
                    : forecastingDateRange[1] === null
                    ? forecastingDateRange[0].toLocaleDateString(
                        'en-GB',
                        dateOptions
                      ) + ' - '
                    : forecastingDateRange[0].toLocaleDateString(
                        'en-GB',
                        dateOptions
                      ) +
                      ' - ' +
                      forecastingDateRange[1].toLocaleDateString(
                        'en-GB',
                        dateOptions
                      )}
                </div>
              }
            />
            <FormHelperText>Forecast phase dates</FormHelperText>
          </LocalizationProvider>
        </div>
      </div>
      <div className="h-64 mb-10">
        <Line options={graphOptions} data={data} />
      </div>
      {warning && <Typography color="error">{warning}</Typography>}
      {!warning && (
        <div>
          {generationDateRange[1] && (
            <Typography>
              The question generation phase starts today and ends on{' '}
              {moment(generationDateRange[1]).format('Do MMMM YYYY')}.
            </Typography>
          )}
          {forecastingDateRange[0] && forecastingDateRange[1] && (
            <Typography>
              The forecasting phase starts on{' '}
              {moment(forecastingDateRange[0]).format('Do MMMM YYYY')} and{' '}
              {continueForecasting
                ? 'will continue until all questions are resolved.'
                : 'ends on ' +
                  moment(forecastingDateRange[1]).format('Do MMMM YYYY') +
                  '. After this date the outcome will be closed.'}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
