import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../store/slices/userSlice';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { ClipLoader } from 'react-spinners';

export default function PasswordResetView() {
  const dispatch = useDispatch();

  const { token } = useParams();
  const [resetToken, setResetToken] = useState()

  useEffect(() => {
    if (token !== "0") {
      setResetToken(token);
    }
  }, [token]);

  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [resetRequestStatus, setResetRequestStatus] = useState('idle');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    'Choose a new password:'
  );
  const [showP1, setShowP1] = useState(false);
  const handleClickShowP1 = () => setShowP1((show) => !show);
  const [showP2, setShowP2] = useState(false);
  const handleClickShowP2 = () => setShowP2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const changePassword = (event) => setP1(event.target.value);
  const changePasswordConf = (event) => setP2(event.target.value);

  const handleReset = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    const canReset =
      [p1, p2].every(Boolean) &&
      resetRequestStatus === 'idle' &&
      p1 === p2;
    if (canReset) {
      setIsMounted(true);
      try {
        setResetRequestStatus('pending')
        let payload = {
          token: token,
          password_str: p1
        };
        await dispatch(resetPassword(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setSuccess(true);
            } else {
              throw new Error('Failed to reset password.');
            }
          });
      } catch (err) {
        if (err.name && err.name === 'SyntaxError') {
          setErrorMessage('Failed to reset password. Possible server error.');
        } else {
          setErrorMessage(`Failed to reset password: ${err.message}`);
        }
      }
    } else if (!p1 || !p2) {
      setErrorMessage(
        'Please ensure you enter your new password in both fields before submitting.'
      );
    } else if (p1 !== p2) {
      setErrorMessage('Please enter valid passwords');
    } else {
      setErrorMessage('Failed to reset passwords.');
    }
    setSuccessMessage('Your password has been sucessfully changed.')
    setResetRequestStatus('idle');
    setIsMounted(false);
  };

  const backToLogin = () => {
    navigate('/login')
  }

  return (
    <div className="flex justify-center">
      <Card
        sx={{ p: 6, mx: 6, my: 12 }}
        className="lg:w-2/5 md:w-1/2 sm:w-3/5"
        raised>
        <form className="ResetView">
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
            className="text-center">{successMessage}</Typography>
          <Typography color="error">{errorMessage}</Typography>

          {!success && (
            <div className="w-full mt-2">
              <TextField
                type={showP1 ? 'text' : 'password'}
                id="outlined-basic"
                label="Choose new password"
                variant="outlined"
                fullWidth
                sx={{
                  '& fieldset': {
                    borderRadius: '9999px'
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowP1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showP1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }}
                value={p1}
                onChange={(event) => changePassword(event)}
              />
            </div>
          )}
          {!success && (
            <div className="w-full mt-2">
              <TextField
                type={showP2 ? 'text' : 'password'}
                id="outlined-basic1"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                sx={{
                  '& fieldset': {
                    borderRadius: '9999px'
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowP2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showP2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }}
                value={p2}
                onChange={(event) => changePasswordConf(event)}
              />
            </div>
          )}

          <div className="flex w-full mt-5 mb-5">
            <Button
              type="submit"
              variant="contained"
              onClick={success ? backToLogin : handleReset}
              disabled={isMounted}
              sx={{
                borderRadius: '9999px'
              }}
              className="w-full">
              {isMounted ? (
                <ClipLoader color="#ffffff" loading={true} size={50} />
              ) : (
                !success ? 'Change Password' : 'Back to login'
              )}
            </Button>
          </div>
        </form>
      </Card>

    </div>
  );
}
