import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

const questionnairesAdapter = createEntityAdapter({});
const measuresAdapter = createEntityAdapter();

const measuresInitialState = measuresAdapter.getInitialState({
  status: 'idle',
  error: null
});

const initialState = questionnairesAdapter.getInitialState({
  status: 'idle',
  error: null,
  measures: measuresInitialState
});

export const fetchQuestionnaires = createAsyncThunk(
  'questionnaires/fetchQuestionnaires',
  async () => {
    const response = await hivemindAPI.fetch('/api/questionnaires');
    return response.json();
  }
);

export const createQuestionnaireResponse = createAsyncThunk(
  'questionnaires/createQuestionnaireResponse',
  async (payload) => {
    const response = await hivemindAPI.post(
      `/api/questionnaires/${payload.questionnaireId}/responses`,
      {
        questionnaire_id: payload.questionnaireId,
        measure_ids: payload.measureIds,
        response_values: payload.responseValues
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchQuestionnaires.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchQuestionnaires.fulfilled, (state, action) => {
        state.status = 'succeeded';
        questionnairesAdapter.addMany(state, action.payload.data);
        measuresAdapter.upsertMany(
          state.measures,
          action.payload.data.flatMap((questionnaire) => questionnaire.measures)
        );
      })
      .addCase(fetchQuestionnaires.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const {} = questionnairesSlice.actions;

export default questionnairesSlice.reducer;

export const {
  selectAll: selectAllQuestionnaires,
  selectById: selectQuestionnaireById,
  selectIds: selectQuestionnairesIds
} = questionnairesAdapter.getSelectors((state) => state.questionnaires);

export const {
  selectAll: selectAllMeasures,
  selectById: selectMeasureById,
  selectIds: selectMeasureIds
} = measuresAdapter.getSelectors((state) => state.questionnaires.measures);
