/* eslint-disable no-template-curly-in-string */
import { Button, Card, Typography, useTheme } from '@mui/material';
import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOutcomeById,
  selectQuestionEvaluationFilter,
  selectQuestionsByOutcome,
  setEvaluationDistributionFilter
} from '../../store/slices/outcomeSlice';
export default function EvaluationFilter({ className, outcomeId }) {
  const dispatch = useDispatch();

  const theme = useTheme();

  const outcome = useSelector((state) => selectOutcomeById(state, outcomeId));

  const questionEvaluationFilter = useSelector((state) =>
    selectQuestionEvaluationFilter(state, outcomeId)
  );

  const questionEntities = useSelector((state) =>
    selectQuestionsByOutcome(state, outcomeId)
  );

  const isDarkMode = localStorage.getItem('mode') === 'dark';

  const getCategoryButtonBgColour = (evaluationCategory) => {
    if (isDarkMode) {
      switch (evaluationCategory.value) {
        case 1:
          return 'from-rose-400 to-red-300 group-hover:from-rose-400 group-hover:to-red-300';
        case 2:
          return 'from-amber-400 to-orange-300 group-hover:from-amber-400 group-hover:to-orange-300';
        case 3:
          return 'from-cyan-300 to-blue-400 group-hover:from-cyan-300 group-hover:to-blue-400';
        case 4:
          return 'from-emerald-400 to-teal-300 group-hover:from-emerald-400 group-hover:to-teal-300';
        case 5:
          return 'from-lightGreen-400 to-green-300 group-hover:from-lightGreen-400 group-hover:to-green-300';
        default:
          return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
      }
    } else {
      switch (evaluationCategory.value) {
        case 1:
          return 'from-red-50 to-red-50 group-hover:from-rose-100 group-hover:to-red-100';
        case 2:
          return 'from-orange-50 to-orange-50 group-hover:from-amber-300 group-hover:to-orange-100';
        case 3:
          return 'from-blue-50 to-blue-50 group-hover:from-cyan-100 group-hover:to-blue-100';
        case 4:
          return 'from-teal-50 to-teal-50 group-hover:from-emerald-100 group-hover:to-teal-100';
        case 5:
          return 'from-green-50 to-green-50 group-hover:from-lightGreen-100 group-hover:to-green-100';
        default:
          return 'from-blue-50 to-blue-50 group-hover:from-cyan-100 group-hover:to-blue-100';
      }
    }
  };

  const getSelectedCategoryButtonBgColour = (evaluationCategory) => {
    if (isDarkMode) {
      switch (evaluationCategory.value) {
        case 1:
          return 'from-rose-500 to-red-900 group-hover:from-rose-900 group-hover:to-red-900';
        case 2:
          return 'from-amber-600 to-orange-500 group-hover:from-amber-600 group-hover:to-orange-500';
        case 3:
          return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
        case 4:
          return 'from-emerald-500 to-teal-500 group-hover:from-emerald-500 group-hover:to-teal-500';
        case 5:
          return 'from-lightGreen-500 to-green-500 group-hover:from-lightGreen-500 group-hover:to-green-500';
        default:
          return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
      }
    } else {
      switch (evaluationCategory.value) {
        case 1:
          return 'from-rose-500 to-red-500 group-hover:from-rose-500 group-hover:to-red-500';
        case 2:
          return 'from-amber-600 to-orange-500 group-hover:from-amber-600 group-hover:to-orange-500';
        case 3:
          return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
        case 4:
          return 'from-emerald-500 to-teal-500 group-hover:from-emerald-500 group-hover:to-teal-500';
        case 5:
          return 'from-lightGreen-500 to-green-500 group-hover:from-lightGreen-500 group-hover:to-green-500';
        default:
          return 'from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500';
      }
    }
  };

  const getCategoryTextBgColour = (evaluationCategory) => {
    switch (evaluationCategory.value) {
      case 1:
        return 'from-rose-100 to-red-100';
      case 2:
        return 'from-amber-200 to-orange-100';
      case 3:
        return 'from-cyan-100 to-blue-100';
      case 4:
        return 'from-emerald-100 to-teal-100';
      case 5:
        return 'from-lightGreen-100 to-green-100';
      default:
        return 'from-cyan-100 to-blue-100';
    }
  };

  const getEvaluationCount = (evaluationCategory) => {
    let count = 0;
    for (const q of Object.entries(questionEntities)) {
      if (q[1].average_evaluation_category.value === evaluationCategory.value) {
        count++;
      }
    }
    return count;
  };

  const getTotalEvaluations = (entries) => {
    let sum = 0;
    for (const q of entries) {
      if (q.evaluations !== null) {
        sum++;
      }
    }
    return sum;
  };

  const getEvaluationWidth = (evaluationCategory) => {
    let count = getEvaluationCount(evaluationCategory);
    return (count / getTotalEvaluations(Object.entries(questionEntities))) * 50;
  };

  const isFilterSelected = (evalCategory) => {
    let type = evaluationMap[evalCategory.value];
    switch (type) {
      case 'veryLow':
        return questionEvaluationFilter.veryLow;
      case 'low':
        return questionEvaluationFilter.low;
      case 'medium':
        return questionEvaluationFilter.medium;
      case 'high':
        return questionEvaluationFilter.high;
      case 'veryHigh':
        return questionEvaluationFilter.veryHigh;
      default:
        return false;
    }
  };

  const filterClick = (value) => {
    let payload = {
      outcomeId: outcomeId
    };
    payload[evaluationMap[value.value]] = !isFilterSelected(value);
    dispatch(setEvaluationDistributionFilter(payload));
  };

  let content;

  let evaluationCategoryContent = [];

  const evaluationMap = {
    1: 'veryLow',
    2: 'low',
    3: 'medium',
    4: 'high',
    5: 'veryHigh'
  };

  for (const evaluationCategory of outcome.pre_multiplied_categories) {
    let ButtonBgColour = !isFilterSelected(evaluationCategory)
      ? getCategoryButtonBgColour(evaluationCategory)
      : getSelectedCategoryButtonBgColour(evaluationCategory);
    let darkModeOpacity = isFilterSelected(evaluationCategory) ? 1 : 0.4;
    content = (
      <div
        key={evaluationCategory.value}
        className={`flex-col mx-2`}
        style={{
          minWidth: getEvaluationWidth(evaluationCategory) + 8 + '%'
        }}>
        <div className={`w-full`}>
          <Button
            sx={{
              borderRadius: '0.5rem',
              p: '0.125rem',
              overflow: 'hidden',
              position: 'relative',
              '&:hover': {
                color: theme.palette.secondary.main
              },
              opacity: isDarkMode ? darkModeOpacity : 1
            }}
            onClick={() => filterClick(evaluationCategory)}
            className={`relative flex w-full items-center justify-center group bg-gradient-to-br ${ButtonBgColour}`}>
            <div
              style={{
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
                transitionDuration: '75ms',
                borderRadius: '0.375rem',
                transitionProperty: 'all',
                backgroundImage: isFilterSelected(evaluationCategory)
                  ? ''
                  : theme.palette.background.paper,
                backgroundColor: isFilterSelected(evaluationCategory)
                  ? `linear-gradient(to bottom right, ${getCategoryTextBgColour(
                      evaluationCategory
                    )}`
                  : ''
              }}
              className={`relative px-2 py-2 w-full h-full grid grid-cols-3 rounded-md group-hover:bg-opacity-0`}>
              <div></div>
              <Typography
                color="text.primary"
                sx={{
                  fontWeight: 500,
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
                className="self-center justify-self-center font-sans uppercase">
                {getEvaluationCount(evaluationCategory)}
              </Typography>
            </div>
          </Button>
        </div>
        <div className={`w-full text-center`}>
          <Typography
            color="text.primary"
            sx={{
              fontWeight: 500,
              '&:hover': {
                color: 'primary.main'
              }
            }}
            className="justify-self-stretch">
            {evaluationCategory.name}
          </Typography>
        </div>
      </div>
    );
    evaluationCategoryContent.push(content);
  }

  if (
    outcome.statuses.includes('Evaluation') &&
    getTotalEvaluations(Object.entries(questionEntities)) > 0
  ) {
    return (
      <div className="justify-self-stretch m-5 px-5 py-4 overflow-visible">
        <Card
          id={'FilterCard'}
          sx={{
            transition: '0.3s',
            boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
            '&:hover': {
              boxShadow: '0 14px 37px -9.125px rgba(0,0,0,0.3)',
              bgcolor: 'hover'
            }
          }}>
          <div className="flex justify-center my-3 px-5 w-full overflow-visible">
            <Typography
              color="text.primary"
              sx={{
                fontWeight: 500,
                '&:hover': {
                  color: 'primary.main'
                }
              }}
              className="justify-self-stretch">
              {'Filter by Average Evaluation'}
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row justify-evenly my-3 px-7 w-full overflow-visible">
            {evaluationCategoryContent}
          </div>
        </Card>
      </div>
    );
  } else {
    return null;
  }
}
