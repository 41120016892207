import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ukLocale from 'date-fns/locale/en-GB';
import { useState } from 'react';
import TimelineSlider from './sliders/TimelineSlider';

export default function OutcomeTimeline({
  generationEndDate,
  setGenerationEndDate,
  forecastingStartDate,
  setForecastingStartDate,
  outcomeEndDate,
  setOutcomeEndDate
}) {
  const [timesIncreased, setTimesIncreased] = useState(1);
  const [today] = useState(() => {
    let date = new Date();
    date.getDate();
    return date.getTime();
  });
  const increaseTimelineLength = () => {
    setOutcomeEndDate(outcomeEndDate + 864000000 * timesIncreased);
    setTimesIncreased(timesIncreased + 1);
  };
  const reduceTimelineLength = () => {
    if (outcomeEndDate > today + 864000000) {
      setOutcomeEndDate(outcomeEndDate - 864000000 * timesIncreased);
      timesIncreased > 1
        ? setTimesIncreased(timesIncreased - 1)
        : setTimesIncreased(1);
    }
  };
  return (
    <div className="my-3 mr-2 w-full">
      <Box
        sx={{}}
        className="grid grid-cols-9 lg:grid-cols-12 gap-x-3 items-center p-1">
        <div className="col-span-11 my-5">
          <TimelineSlider
            generationEndDate={generationEndDate}
            setGenerationEndDate={setGenerationEndDate}
            forecastingStartDate={forecastingStartDate}
            setForecastingStartDate={setForecastingStartDate}
            outcomeEndDate={outcomeEndDate}
            setOutcomeEndDate={setOutcomeEndDate}
          />
        </div>
        <div className="col-span-1 justify-center mx-2 mb-2">
          <Tooltip title="Extend Timeline">
            <IconButton onClick={increaseTimelineLength}>
              <Add fontSize="large"></Add>
            </IconButton>
          </Tooltip>
          <Tooltip title="Decrease Timeline">
            <IconButton onClick={reduceTimelineLength}>
              <Remove fontSize="large"></Remove>
            </IconButton>
          </Tooltip>
        </div>
      </Box>
      <div className="flex m-2 justify-center">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ukLocale}>
          <DatePicker
            clearable={false}
            label="Outcome End Date"
            value={outcomeEndDate}
            onChange={(newValue) => {
              setOutcomeEndDate(newValue);
            }}
            minDate={Date.now()}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
