import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect } from 'react';

export default function CategoricalMeasure({ measure, value, setValue }) {
  useEffect(() => {
    setValue(measure.eligible_answers[0]);
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let radioOptions;
  radioOptions = measure.eligible_answers.map((category, index) => (
    <FormControlLabel
      key={index}
      value={category.answer}
      control={<Radio />}
      label={
        <Typography sx={{ fontSize: '0.8rem' }}>{category.answer}</Typography>
      }
    />
  ));

  return (
    <RadioGroup
      row
      value={value}
      onChange={handleChange}
      sx={{ display: 'flex', justifyContent: 'center' }}>
      {radioOptions}
    </RadioGroup>
  );
}
