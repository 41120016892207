import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { changeUserPassword } from '../../store/slices/userSlice';

import {
  Button,
  Card,
  TextField,
  Typography
} from '@mui/material';
import { AuthContext, checkTokenStatus } from '../../App';

export default function ResetUserPasswordModal({ shown, close, username }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changeRequestStatus, setChangeRequestStatus] = useState('idle');
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const changePassword = (event) => setPassword(event.target.value);
  const changeConfirmedPassword = (event) => 
    setConfirmedPassword(event.target.value);

  const checkPasswordsMatch = () => {
    return password === confirmedPassword;
  };
  
  const canChangePassword =
    [password].every((val) => val !== '') &&
    checkTokenStatus() &&
    checkPasswordsMatch() &&
    changeRequestStatus === 'idle';

  const handlePasswordChange = async () => {
    if (canChangePassword) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setChangeRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          username: username,
          newPassword: password,
          auth_token: token
        };
        await dispatch(changeUserPassword(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setResponseMessage(`Successfully updated password`);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to  update password: ${err.message}`);
      } finally {
        if (isMounted) {
          setChangeRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (password === '') {
      setErrorMessage('Please set the password before submitting');
    } else if (!checkPasswordsMatch()) {
      setPasswordMatchError(true);
    } else {
      setErrorMessage('Password could not be updated.');
    }
  };

  const closeModal = () => {
    close();
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3 z-10"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
            className="text-center pb-1">
            Change user password
          </Typography>

          <Typography
            sx={{ fontSize: '0.8rem', fontWeight: 400 }}
            className="text-center pb-3">
            This will change the user's password
          </Typography>
        
          {!responseMessage && (
            <div>
              <div className="flex w-full">
                <div className="w-1/2 mx-2">
                  <Typography className="">Password</Typography>
                  <TextField
                    name="blank"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    value={password}
                    onChange={(event) => changePassword(event)}
                    placeholder="Enter new password"
                    sx={{ my: 1.2 }}
                    className="w-full"
                  />
                </div>
                <div className="w-1/2 mx-2">
                  <Typography className="">Confirm Password</Typography>
                  <TextField
                    name="blank"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    value={confirmedPassword}
                    onChange={(event) => changeConfirmedPassword(event)}
                    placeholder="Confirm new password"
                    sx={{ my: 1.2 }}
                    error={passwordMatchError}
                    helperText={
                      passwordMatchError ? 'Please check passwords match' : ''
                    }
                    className="w-full"
                  />
                </div>
              </div>

              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}

              <div className="flex mt-4">
                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}
                    className="w-full">
                    Cancel
                  </Button>
                </div>

                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    onClick={handlePasswordChange}
                    className="w-full">
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}

          {responseMessage && (
            <div>
              {responseMessage && (
                <Typography
                  sx={{ my: 1.2 }}
                  className="text-center mt-2 py-2"
                  color="success.main">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  variant="contained"
                  onClick={closeModal}
                  className="w-6/12">
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}
