import { Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../../App';
import {
  createQuestionnaireResponse,
  selectQuestionnaireById
} from '../../../store/slices/questionnaireSlice';
import { fetchUsers } from '../../../store/slices/userSlice';
import SuccessModal from '../../modals/SuccessModal';
import QuestionnaireMeasure from './QuestionnaireMeasure';

export default function Questionnaire({ questionnaireId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const questionnaire = useSelector((state) =>
    selectQuestionnaireById(state, questionnaireId)
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const { setIsLoggedIn } = useContext(AuthContext);
  const [measureIds, setMeasureIds] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    if (questionnaire) {
      setMeasureIds(questionnaire.measures.map((measure) => measure.id));
    }
  }, [questionnaire]);

  const [responseValues, setResponseValues] = useState([]);

  const openSettingsView = (problemId) => {
    navigate(`/account-settings`);
  };

  const addResponse = (measureIndex, value) => {
    let responseCopy = responseValues;
    responseCopy[measureIndex] = value;
    setResponseValues(responseCopy);
  };

  const validateResponses = () => {
    return (
      questionnaire &&
      measureIds.length === questionnaire.measures.length &&
      responseValues.length === questionnaire.measures.length
    );
  };

  const canCreate =
    [questionnaire, checkTokenStatus(), validateResponses()].every(Boolean) &&
    addRequestStatus === 'idle';

  const createResponse = async () => {
    if (canCreate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          questionnaireId: questionnaire.id,
          measureIds: measureIds,
          responseValues: responseValues,
          auth_token: token
        };
        await dispatch(createQuestionnaireResponse(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setResponseMessage(`Successfully Completed Questionnaire!`);
              dispatch(fetchUsers(token));
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to submit questionnaire: ${err.message}`);
      } finally {
        if (isMounted) {
          setAddRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (!questionnaire || !measureIds)
        setErrorMessage('Questionnaire could not be created.');
      if (!responseValues || responseValues.length !== measureIds.length)
        setErrorMessage(
          'Please ensure all measures have been completed before submitting.'
        );
    }
  };

  let measures;
  if (questionnaire) {
    measures = questionnaire.measures.map((measure, index) => (
      <QuestionnaireMeasure
        key={index}
        measureId={measure.id}
        addResponses={(val) => addResponse(index, val)}
      />
    ));
  }

  return (
    <div>
      <Typography color="error">{errorMessage}</Typography>

      <div className="my-4 max-h-[30rem] overflow-y-auto rounded">
        {measures}
      </div>
      <div className="flex justify-end">
        <Button
          sx={{ my: 0.6 }}
          variant="contained"
          onClick={() => createResponse(true)}
          className="inline-flex items-center">
          Submit
        </Button>
      </div>
      {responseMessage && (
        <div>
          <SuccessModal
            shown={responseMessage}
            close={openSettingsView}
            successMessage={responseMessage}
          />
        </div>
      )}
    </div>
  );
}
