import { ViewAgenda, ViewList } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserPreferences,
  setQuestionType
} from '../../store/slices/userSlice';

export default function QuestionTypeToggle() {
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const dispatch = useDispatch();

  const handleTypeChange = (event, newType) => {
    localStorage.setItem('questionType', newType);
    dispatch(setQuestionType(newType));
  };

  return (
    <div>
      <ToggleButtonGroup
        value={userPreferences.question_type}
        exclusive
        onChange={handleTypeChange}
        aria-label="QuestionInputType"
        sx={{}}>
        <ToggleButton color="primary" sx={{}} variant="outlined" value="masked">
          <ViewAgenda />
          Structured
        </ToggleButton>
        <ToggleButton color="primary" sx={{}} variant="outlined" value="freeform">
          <ViewList />
          Freeform
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
