import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { passwordResetLink } from '../store/slices/userSlice';

import {
  Button,
  Card,
  TextField,
  Typography
} from '@mui/material';
import { ClipLoader } from 'react-spinners';

export default function PasswordResetView() {
  const dispatch = useDispatch();

  const [recoveryValue, setRecoveryValue] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [resetRequestStatus, setResetRequestStatus] = useState('idle');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    'Send Password Reset Link'
  );
  const [disabled, setDisabled] = useState(false)
  const [sentFirstEmail, setSentFirstEmail] = useState(false)

  const changeRecoveryValue = (event) => setRecoveryValue(event.target.value);

  const handleReset = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    const canReset =
      [recoveryValue].every(Boolean) &&
      resetRequestStatus === 'idle'
    if (canReset) {
      setIsMounted(true);
      try {
        let payload = {}
        if (validateEmail(recoveryValue)) {
          payload = {
            email: recoveryValue,
          };
        } else {
          payload = {
            username: recoveryValue,
          };
        }
        setResetRequestStatus('pending')
        await dispatch(passwordResetLink(payload))
          .unwrap()
          .then((response) => {
            if (response.status === 'success') {
              setSuccessMessage('A password reset link has been sent.')
              setSuccess(true);
              setSentFirstEmail(true)
              setDisabled(true);
              setTimeout(() => {
                setDisabled(false);
              }, 60000)
            } else {
              throw new Error('Failed to send link.');
            }
          });
      } catch (err) {
        if (err.name && err.name === 'SyntaxError') {
          setErrorMessage('Failed to send reset link. Possible server error.');
        } else {
          setErrorMessage(`Failed to reset link: ${err.message}`);
        }
      }
    } else if (!recoveryValue) {
      setErrorMessage(
        'Please ensure you enter your username or email before trying to reset your password.'
      );
    } else {
      setErrorMessage('Failed to register.');
    }
    setResetRequestStatus('idle');
    setIsMounted(false);
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="flex justify-center">
      <Card
        sx={{ p: 6, mx: 6, my: 12 }}
        className="lg:w-2/5 md:w-1/2 sm:w-3/5"
        raised>
        <form className="ResetView">
          {/* <Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}} className="text-center">Log In</Typography> */}
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
            className="text-center">{successMessage}</Typography>
          <Typography color="error">{errorMessage}</Typography>
          <div className="w-full mt-2">
            <TextField
              id="outlined-basic"
              label="Username or email address"
              variant="outlined"
              fullWidth
              sx={{
                '& fieldset': {
                  borderRadius: '9999px'
                }
              }}
              type="text"
              value={recoveryValue}
              onChange={(event) => changeRecoveryValue(event)}
            />
          </div>
          <div className="flex w-full mt-5 mb-5">
            <Button
              type="submit"
              variant="contained"
              onClick={handleReset}
              disabled={isMounted || disabled}
              sx={{
                borderRadius: '9999px'
              }}
              className="w-full">
              {isMounted ? (
                <ClipLoader color="#ffffff" loading={true} size={50} />
              ) : disabled ? 'Please wait 60s before resending email'
                : sentFirstEmail ? 'Resend email' : 'Send email'}
            </Button>
          </div>
        </form>
      </Card>

    </div>
  );
}
