import React, { useContext, useEffect, useState } from 'react';
import Dropdown from '../../components/other/Dropdown';

import {
  fetchQuestionsByActivity,
  selectOutcomesSort,
  setOutcomesSort,
} from '../../store/slices/outcomeSlice';
import {
  selectUserPreferences,
  setOutcomeLayout
} from '../../store/slices/userSlice';

import {
  Button,
  Card,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';

import { FilterAlt, ViewAgenda, ViewList } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import FilterModal from '../modals/FilterModal';

export default function OutcomeFilterSection() {
  const { userData } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);

  const [fetchActivityStatus, setFetchActivityStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const outcomeSort = useSelector((state) => selectOutcomesSort(state));
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const [showFilterModal, setShowFilterModal] = useState(false);

  const dispatch = useDispatch();

  const options = [
    'Newest',
    'Activity',
    'Recent Activity',
    'Ending',
    'Trending'
  ];

  const handleSelectedOption = (option) => {
    if (option === 'Newest') {
      dispatch(
        setOutcomesSort({
          key: 'Newest',
          type: 'created'
        })
      );
    } else if (option === 'Ending') {
      dispatch(
        setOutcomesSort({
          key: 'Ending',
          type: 'endAt'
        })
      );
    } else if (option === 'Activity') {
      dispatch(
        setOutcomesSort({
          key: 'Activity',
          type: 'activity'
        })
      );
    } else if (option === 'Recent Activity') {
      dispatch(
        setOutcomesSort({
          key: 'Recent Activity',
          type: 'recentActivity'
        })
      );
    } else if (option === 'Trending') {
      dispatch(
        setOutcomesSort({
          key: 'Trending',
          type: 'popular'
        })
      );
    }
  };

  const handleOrderChange = (order) => {
    if (order === 'asc') {
      dispatch(setOutcomesSort({ order: 'asc' }));
    } else if (order === 'desc') {
      dispatch(setOutcomesSort({ order: 'desc' }));
    } else {
      throw new Error('Incorrect parameter.')
    }
  };

  const handleLayoutChange = (event, newLayout) => {
    localStorage.setItem('outcomeLayout', newLayout);
    dispatch(setOutcomeLayout(newLayout));
  };

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    if (isMounted) {
      try {
        if (fetchActivityStatus === 'idle' && userData.id) {
          setFetchActivityStatus('pending');
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchQuestionsByActivity({
              userId: userData.id,
              auth_token: token
            })
          ).unwrap()
            .catch((err) => {
              setErrorMessage(`Failed to get outcome data: ${err.message}`);
            })
        }
      } catch (err) {
        setErrorMessage(`Failed to get outcome data: ${err.message}`);
      } finally {
        if (isMounted) {
          setFetchActivityStatus('succeeded');
          isMounted = false;
        }
      }
    }
  }, [fetchActivityStatus, dispatch, userData]);

  return (
    <Card sx={{ m: 3, px: 1, py: 0.6 }}>
      <div className="flex justify-end">
        <Collapse className="w-full" in={!collapsed}>
          <div className="w-full flex justify-center md:justify-between items-center flex-wrap -mt-3">
            <div className="flex">
              <div
                className={`flex justify-center items-center mt-3 flex-wrap ${userPreferences.outcome_layout === 'card' ? 'px-1' : 'px-3'
                  }`}>
                <Dropdown
                  options={options}
                  selectedOption={outcomeSort.key}
                  setSelectedOption={(option) => handleSelectedOption(option)}
                  chevrons={true}
                  selectedChevron={outcomeSort.order === 'asc' ? 'up' : 'down'}
                  upChevron={() => handleOrderChange('asc')}
                  downChevron={() => handleOrderChange('desc')}
                  prefixText="Sort by: "
                />
              </div>
              <div
                className={`flex justify-center mt-3 items-centerflex-wrap ${userPreferences.outcome_layout === 'card' ? 'px-1' : 'px-3'
                  }`}>
                <Button
                  variant="outlined"
                  onClick={() => setShowFilterModal(true)}>
                  Filter
                  <FilterAlt />
                </Button>
              </div>
            </div>
            <div
              className={`mt-3 ${userPreferences.outcome_layout === 'card' ? 'px-2' : 'px-2'
                }`}>
              {userPreferences !== undefined && (
                <ToggleButtonGroup
                  value={userPreferences.outcome_layout}
                  exclusive
                  onChange={handleLayoutChange}
                  aria-label="Layout"
                  sx={{}}>
                  <ToggleButton
                    size={
                      userPreferences.outcome_layout === 'card'
                        ? 'small'
                        : 'medium'
                    }
                    color="primary"
                    sx={{}}
                    variant="outlined"
                    value="card">
                    <ViewAgenda />
                    Card
                  </ToggleButton>
                  <ToggleButton
                    size={
                      userPreferences.outcome_layout === 'card'
                        ? 'small'
                        : 'medium'
                    }
                    color="primary"
                    sx={{}}
                    variant="outlined"
                    value="detail">
                    <ViewList />
                    Detail
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </div>
          </div>
        </Collapse>
        <button className="mr-1" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <BsChevronDoubleDown size={15} />
          ) : (
            <BsChevronDoubleUp size={15} />
          )}
        </button>
      </div>
      {showFilterModal && (
        <FilterModal
          shown={showFilterModal}
          close={() => {
            setShowFilterModal(false);
          }}
        />
      )}
    </Card>
  );
}
